export const INSTITUTIONS_TYPES = {
  CLINIC: "Clinic",
  HOSPITAL: "Hospital",
  CENTER: "Grooming Center",
};

export const INSTITUTIONS_TYPES_COLORS = {
  CLINIC: "success",
  HOSPITAL: "info",
  CENTER: "warning",
};

export const petServicesOptions = [
  {
    label: "Consultation",
    value: "CONSULTATION",
  },
  {
    label: "Surgery",
    value: "SURGERY",
  },
  {
    label: "Vaccination",
    value: "VACCINATION",
  },
  {
    label: "Grooming Service",
    value: "GROOMING",
  },
];

export const groomingServicesOptions = [
  {
    label: "Grooming Service",
    value: "GROOMING",
  },
];
