import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { handleResponseError } from "../../utils/handleResponseError";
import { classNames } from "primereact/utils";
import {
  BirdBreed,
  CatBreed,
  DogBreed,
  animalTypes,
  animalGenderOptions,
  animalHairOptions,
} from "../../types/animals.types";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { InputTextarea } from "primereact/inputtextarea";
import { Chips } from "primereact/chips";
import { Button } from "primereact/button";

function AddRescueAnimal({ institutionId, setModalVisible, setRescueAnimals }) {
  const [loading, setLoading] = useState(false);

  const addRescueAnimalForm = useFormik({
    initialValues: {
      breed: null,
      color: null,
      disabilities: null,
      birthday: null,
      gender: null,
      hair: null,
      institution: institutionId,
      isForAdopt: true,
      name: null,
      story: null,
      traits: null,
      type: null,
      weight: null,
    },
    validate: (data) => {
      let errors = {};

      let requiredFields = [
        {
          name: "breed",
          label: "Breed",
        },
        {
          name: "type",
          label: "Type",
        },
        {
          name: "gender",
          label: "Gender",
        },
        {
          name: "story",
          label: "Story",
        },
        {
          name: "name",
          label: "Name",
        },
        {
          name: "hair",
          label: "Hair",
        },
      ];

      requiredFields.forEach((field) => {
        if (!data[field.name]) {
          errors[field.name] = `${field.label} is required`;
        }
      });

      return errors;
    },
    onSubmit: (data) => {
      if (data) {
        setLoading(true);

        let endpoint = "/animal/private";

        axios
          .post(endpoint, data)
          .then((res) => {
            setModalVisible(false);
            res.data && setRescueAnimals((prev) => [...prev, res.data]);
          })
          .catch((e) => {
            handleResponseError(e);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
  });

  const isFormFieldInvalid = (name) =>
    !!(addRescueAnimalForm.touched[name] && addRescueAnimalForm.errors[name]);

  const getFormErrorMessage = (name) => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{addRescueAnimalForm.errors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const handleBreedOptions = () => {
    switch (addRescueAnimalForm.values.type) {
      case "DOG":
        return DogBreed;

      case "CAT":
        return CatBreed;
      case "BIRD":
        return BirdBreed;

      default:
        return [];
    }
  };

  useEffect(() => {
    addRescueAnimalForm.resetForm();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    addRescueAnimalForm.setFieldValue("breed", null);
    // eslint-disable-next-line
  }, [addRescueAnimalForm.values.type]);

  return (
    <div>
      <span className="font-bold my-10">Animal Information</span>
      <hr className="pb-10" />
      <div className="grid grid-cols-2 gap-5">
        <form
          onSubmit={addRescueAnimalForm.handleSubmit}
          className="col-span-2"
        >
          <div className="grid grid-cols-2 gap-3">
            <div className="col-span-1">
              <span className=" w-full">
                <label htmlFor="type">Type</label>
                <Dropdown
                  options={animalTypes}
                  optionLabel="label"
                  optionValue="value"
                  id="type"
                  name="type"
                  value={addRescueAnimalForm.values.type}
                  onChange={(e) => {
                    addRescueAnimalForm.setFieldValue("type", e.value);
                  }}
                  className={classNames({
                    "p-invalid": isFormFieldInvalid("type"),
                    "w-full": true,
                  })}
                />
              </span>
              {getFormErrorMessage("type")}
            </div>
            <div className="col-span-1">
              <span className="w-full">
                <label htmlFor="type">Breed</label>
                <Dropdown
                  options={handleBreedOptions()}
                  id="breed"
                  name="breed"
                  value={addRescueAnimalForm.values.breed}
                  onChange={(e) => {
                    addRescueAnimalForm.setFieldValue("breed", e.value);
                  }}
                  className={classNames({
                    "p-invalid": isFormFieldInvalid("breed"),
                    "w-full": true,
                  })}
                />
              </span>
              {getFormErrorMessage("breed")}
            </div>
            <div className="col-span-1">
              <span className="w-full">
                <label htmlFor="name">Name</label>
                <InputText
                  id="name"
                  name="name"
                  value={addRescueAnimalForm.values.name}
                  onChange={(e) => {
                    addRescueAnimalForm.setFieldValue("name", e.target.value);
                  }}
                  className={classNames({
                    "p-invalid": isFormFieldInvalid("name"),
                    "w-full": true,
                  })}
                />
              </span>
              {getFormErrorMessage("name")}
            </div>
            <div className="col-span-1">
              <span className="w-full">
                <label htmlFor="type">Gender</label>
                <Dropdown
                  options={animalGenderOptions}
                  id="gender"
                  name="gender"
                  value={addRescueAnimalForm.values.gender}
                  onChange={(e) => {
                    addRescueAnimalForm.setFieldValue("gender", e.value);
                  }}
                  className={classNames({
                    "p-invalid": isFormFieldInvalid("gender"),
                    "w-full": true,
                  })}
                />
              </span>
              {getFormErrorMessage("gender")}
            </div>
            <div className="col-span-1">
              <span className="w-full">
                <label htmlFor="weight">Weight (in KGs)</label>
                <InputNumber
                  id="weight"
                  name="weight"
                  value={addRescueAnimalForm.values.weight}
                  onChange={(e) => {
                    addRescueAnimalForm.setFieldValue("weight", e.value);
                  }}
                  className={classNames({
                    "p-invalid": isFormFieldInvalid("weight"),
                    "w-full": true,
                  })}
                />
              </span>
              {getFormErrorMessage("weight")}
            </div>
            <div className="col-span-1">
              <span className="w-full">
                <label htmlFor="hair">Hair</label>
                <Dropdown
                  options={animalHairOptions}
                  id="hair"
                  name="hair"
                  value={addRescueAnimalForm.values.hair}
                  onChange={(e) => {
                    addRescueAnimalForm.setFieldValue("hair", e.value);
                  }}
                  className={classNames({
                    "p-invalid": isFormFieldInvalid("hair"),
                    "w-full": true,
                  })}
                />
              </span>
              {getFormErrorMessage("hair")}
            </div>
            <div className="col-span-1">
              <span className="w-full">
                <label htmlFor="birthday">Birthday</label>
                <Calendar
                  id="birthday"
                  name="birthday"
                  value={addRescueAnimalForm.values.hair}
                  onChange={(e) => {
                    addRescueAnimalForm.setFieldValue("birthday", e.value);
                  }}
                  className={classNames({
                    "p-invalid": isFormFieldInvalid("birthday"),
                    "w-full": true,
                  })}
                />
              </span>
              {getFormErrorMessage("birthday")}
            </div>
            <div className="col-span-1">
              <span className="w-full">
                <label htmlFor="color">Color</label>
                <InputText
                  id="color"
                  name="color"
                  value={addRescueAnimalForm.values.color}
                  onChange={(e) => {
                    addRescueAnimalForm.setFieldValue("color", e.target.value);
                  }}
                  className={classNames({
                    "p-invalid": isFormFieldInvalid("color"),
                    "w-full": true,
                  })}
                />
              </span>
              {getFormErrorMessage("color")}
            </div>
            <div className="col-span-2">
              <span className="w-full">
                <label htmlFor="story">Story</label>
                <InputTextarea
                  id="story"
                  name="story"
                  value={addRescueAnimalForm.values.story}
                  onChange={(e) => {
                    addRescueAnimalForm.setFieldValue("story", e.target.value);
                  }}
                  className={classNames({
                    "p-invalid": isFormFieldInvalid("story"),
                    "w-full": true,
                  })}
                />
              </span>
              {getFormErrorMessage("story")}
            </div>
            <div className="col-span-1">
              <label htmlFor="traits">Traits</label>
              <Chips
                allowDuplicate={false}
                id="traits"
                name="traits"
                pt={{
                  container: {
                    style: {
                      width: "100%",
                    },
                  },
                }}
                value={addRescueAnimalForm.values.traits}
                onChange={(e) => {
                  addRescueAnimalForm.setFieldValue("traits", e.target.value);
                }}
                className={classNames({
                  "p-invalid": isFormFieldInvalid("traits"),
                  "w-full": true,
                })}
              />
              {getFormErrorMessage("traits")}
            </div>
            <div className="col-span-1">
              <label htmlFor="disabilities">Disabilities</label>
              <Chips
                allowDuplicate={false}
                id="disabilities"
                pt={{
                  container: {
                    style: {
                      width: "100%",
                    },
                  },
                }}
                name="disabilities"
                value={addRescueAnimalForm.values.disabilities}
                onChange={(e) => {
                  addRescueAnimalForm.setFieldValue(
                    "disabilities",
                    e.target.value
                  );
                }}
                className={classNames({
                  "p-invalid": isFormFieldInvalid("disabilities"),
                  "w-full": true,
                })}
              />
              {getFormErrorMessage("disabilities")}
            </div>
          </div>
          <div className="flex w-full justify-end">
            <Button
              loading={loading}
              type="submit"
              className="p-button p-button-primary p-mt-5"
            >
              Add
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddRescueAnimal;
