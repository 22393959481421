import React, { useEffect, useState } from "react";
import axios from "axios";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import EditDoctorSchedule from "./components/EditDoctorSchedule";
import EditDoctorServices from "./components/EditDoctorServices";
import { useNavigate, useParams } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";
import { Avatar } from "primereact/avatar";
import { TabPanel, TabView } from "primereact/tabview";
import { Rating } from "primereact/rating";
import { petServicesOptions } from "../../types/institution.types";
import AddDoctorPetService from "../pet-services/AddDoctorPetService";

function ViewDoctor() {
  const params = useParams();
  const navigate = useNavigate();

  const [doctor, setDoctor] = useState(null);
  const [institution, setInstitution] = useState(null);
  const [doctorServices, setDoctorServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isDoctorScheduleVisible, setIsDoctorScheduleVisible] = useState(false);
  const [isDoctorServicesVisible, setIsDoctorServicesVisible] = useState(false);
  const [isPetServiceAddVisible, setIsPetServiceAddVisible] = useState(false);

  const dayColumnTemplate = (rowData) => {
    return (
      <span>{rowData.day.charAt(0).toUpperCase() + rowData.day.slice(1)}</span>
    );
  };

  const fromColumnTemplate = (rowData) => {
    return <span>{rowData.from ? rowData.from : "N/A"}</span>;
  };

  const toColumnTemplate = (rowData) => {
    return <span>{rowData.to ? rowData.to : "N/A"}</span>;
  };

  const serviceTypeBodyTemplate = (rowData) => {
    const serviceTypes = [...petServicesOptions];
    const service = serviceTypes.find(
      (service) => service.value === rowData.type
    );
    return <span>{service.label}</span>;
  };

  useEffect(() => {
    axios
      .get("physician/" + params.doctorId, {
        params: {
          populateInstitution: true,
        },
      })
      .then((res) => {
        setDoctor(res.data);
        setLoading(false);
      })
      .catch((e) => {});
  }, [params.doctorId]);

  useEffect(() => {
    if (doctor) {
      axios
        .get("service/list", {
          params: {
            physician: [doctor._id],
          },
        })
        .then((res) => {
          setDoctorServices(res.data);
        })
        .catch((e) => {});

      axios
        .get(`/institution/${doctor.institution._id}`)
        .then((res) => setInstitution(res.data));
    }
  }, [doctor]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-96">
        <ProgressSpinner />
      </div>
    );
  }

  return (
    <div>
      <Dialog
        header="Edit Doctor Schedule"
        visible={isDoctorScheduleVisible}
        onHide={() => setIsDoctorScheduleVisible(false)}
        style={{ width: "50vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        closeOnEscape
        dismissableMask
      >
        <EditDoctorSchedule
          setVisible={setIsDoctorScheduleVisible}
          doctor={doctor}
          setDoctor={setDoctor}
          ownResource={false}
        />
      </Dialog>
      <Dialog
        header="Edit Doctor Services"
        visible={isDoctorServicesVisible}
        onHide={() => setIsDoctorServicesVisible(false)}
        style={{ width: "50vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        closeOnEscape
        dismissableMask
      >
        <EditDoctorServices />
      </Dialog>

      {doctor && (
        <>
          {" "}
          <div className="flex flex-col md:flex-row gap-5 justify-between items-center">
            <div className="flex flex-col md:flex-row justify-center text-center md:text-start items-center gap-4">
              <div className="flex flex-col gap-2">
                <h1 className="text-md md:text-xl">{doctor.name}</h1>
                <Rating
                  value={doctor.rating}
                  readOnly
                  stars={5}
                  cancel={false}
                />
              </div>
            </div>
            <div className="flex flex-col md:flex-row gap-2">
              <Button
                severity="warning"
                label="Edit Schedule"
                className="p-button-text"
                icon="pi pi-pencil"
                size="small"
                onClick={() => setIsDoctorScheduleVisible(true)}
              />
            </div>
          </div>
          <hr className="my-5" />
          <div className="grid grid-cols-3">
            <div className="col-span-3 bg-gray-50 rounded-md p-5">
              <h2 className="text-lg font-semibold border-b-2 border-slate-100 my-4">
                Institution
              </h2>
              <div
                onClick={() =>
                  navigate("/institutions/" + doctor.institution?._id)
                }
                className="flex items-center gap-2 p-1 hover:bg-gray-100 hover:cursor-pointer"
              >
                <Avatar
                  image={doctor.institution?.avatar}
                  shape="circle"
                  className="w-[40px] h-[40px] md:w-[70px] md:h-[70px]"
                  alt={doctor.institution?.name}
                />
                <span className="text-2xl font-semibold">
                  {doctor.institution?.name}
                </span>
              </div>
            </div>
          </div>
          <hr className="my-5" />
          <div className="grid grid-cols-3">
            <div className="col-span-3">
              <TabView>
                <TabPanel header="Schedule">
                  <DataTable
                    value={doctor.availableHours}
                    className="w-full text-center text-sm"
                    emptyMessage="No times found."
                    size="small"
                  >
                    <Column field="day" header="Day" body={dayColumnTemplate} />
                    <Column
                      field="from"
                      header="From"
                      body={fromColumnTemplate}
                    />
                    <Column field="to" header="To" body={toColumnTemplate} />
                  </DataTable>
                </TabPanel>
                <TabPanel header="Services">
                  <div className="flex justify-end">
                    <Button
                      className="p-button-text p-button-sm my-1"
                      label="Add Doctor Service"
                      icon="pi pi-plus"
                      onClick={() => setIsPetServiceAddVisible(true)}
                    />
                  </div>
                  <DataTable
                    value={doctorServices}
                    className="w-full text-center text-sm"
                    emptyMessage="No services found."
                    size="small"
                  >
                    <Column field="title" header="Title" />
                    <Column
                      field="type"
                      header="Type"
                      body={serviceTypeBodyTemplate}
                    />
                    <Column field="description" header="Description" />
                    <Column
                      header="Price"
                      body={(rowData) => {
                        return (
                          <>
                            {new Intl.NumberFormat("en-EG", {
                              style: "currency",
                              currency: "EGP",
                            }).format(rowData.offers[0].price)}
                          </>
                        );
                      }}
                    />
                  </DataTable>
                </TabPanel>
              </TabView>
            </div>
          </div>
        </>
      )}
      {doctor && institution && (
        <Dialog
          header="Add Service"
          visible={isPetServiceAddVisible}
          style={{ width: "50vw" }}
          onHide={() => setIsPetServiceAddVisible(false)}
        >
          <AddDoctorPetService
            doctorId={doctor._id}
            setModalVisible={setIsPetServiceAddVisible}
            setServices={setDoctorServices}
            institutionId={institution._id}
            institutionType={institution.type}
            representorId={institution.representor._id}
            ownResource={false}
          />
        </Dialog>
      )}
    </div>
  );
}

export default ViewDoctor;
