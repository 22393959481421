import axios from "axios";
import { Formik, Form } from "formik";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { handleResponseError } from "../../utils/handleResponseError";
import { UserContext } from "../../contexts/UserContext";

function Login() {
  const navigate = useNavigate();

  const { setUser } = useContext(UserContext);

  const handleLogin = (values, setSubmitting) => {
    axios
      .post("/identification/authentication/grant/email", values)
      .then((res) => {
        localStorage.setItem("token", res.data.token);

        axios.get("/user/private").then((res) => setUser(res.data));

        navigate("/");
      })
      .catch((e) => {
        handleResponseError(e);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <div className="bg-indigo-400 flex justify-center h-screen w-full items-center">
      <div className="bg-white p-7 shadow-2 rounded lg:w-[500px]">
        <div className="text-center mb-5"></div>

        <div>
          <Formik
            initialValues={{ email: "", password: "" }}
            validate={(values) => {
              const errors = {};
              if (!values.email) {
                errors.email = "Email is required";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "Invalid email address";
              } else if (!values.password) {
                errors.password = "Password is required";
              } else if (values.password.length < 6) {
                errors.password = "Password must be at least 6 characters";
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              handleLogin(values, setSubmitting);
            }}
          >
            {({ isSubmitting, errors, touched, handleChange, values }) => (
              <Form>
                <label
                  htmlFor="email"
                  className="block text-900 font-medium mb-2"
                >
                  Email
                </label>
                <InputText
                  id="email"
                  type="text"
                  placeholder="Email address"
                  className="w-full mb-3"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                />
                <div className="text-red-500 text-xs italic mb-3">
                  {errors.email && touched.email && errors.email}
                </div>
                <label
                  htmlFor="password"
                  className="block text-900 font-medium mb-2"
                >
                  Password
                </label>
                <InputText
                  id="password"
                  type="password"
                  placeholder="Password"
                  className="w-full mb-3"
                  name="password"
                  onChange={handleChange}
                  value={values.password}
                />
                <div className="text-red-500 text-xs italic mb-3">
                  {errors.password && touched.password && errors.password}
                </div>
                <div className="flex align-items-center justify-content-between mb-6">
                  <Button
                    onClick={() => navigate("/forgot-password")}
                    size="small"
                    type="button"
                    className="font-medium no-underline ml-2 p-button-text px-1 text-right cursor-pointer"
                  >
                    Forgot your password?
                  </Button>
                </div>

                <Button
                  label="Sign In"
                  icon="pi pi-user"
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  className="w-full bg-indigo-500 border-0"
                  type="submit"
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default Login;
