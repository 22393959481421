import axios from "axios";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { classNames } from "primereact/utils";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { handleResponseError } from "../../utils/handleResponseError";

function AddUser() {
  const navigate = useNavigate();

  const [countries, setCountries] = useState([]);

  const addUserForm = useFormik({
    initialValues: {
      name: "",
      email: "",
      country: "",
      password: "",
      role: "REPRESENTOR",
    },

    validate: (data) => {
      const errors = {};

      let requiredFields = [
        {
          name: "name",
          label: "Name",
        },
        {
          name: "email",
          label: "Email",
        },
        {
          name: "country",
          label: "Country",
        },
        {
          name: "password",
          label: "Password",
        },
      ];

      requiredFields.forEach((field) => {
        if (!data[field.name]) {
          errors[field.name] = `${field.label} is required`;
        }
      });

      if (data.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.email)) {
        errors.email = "Invalid email address";
      }

      return errors;
    },

    onSubmit: (data) => {
      addUserForm.setSubmitting(true);
      axios
        .post("/user/email", data)
        .then((res) => {
          toast.success("User created successfully");
          navigate(`/users/${res.data._id}`);
        })
        .catch((e) => {
          handleResponseError(e);
        })
        .finally(() => {
          addUserForm.setSubmitting(false);
        });
    },
  });

  const isFormFieldInvalid = (name) =>
    !!(addUserForm.touched[name] && addUserForm.errors[name]);

  const getFormErrorMessage = (name) => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{addUserForm.errors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  useEffect(() => {
    axios
      .get("/region/list?type=COUNTRY")
      .then((res) => setCountries(res.data));
  }, []);

  return (
    <div>
      <h1 className="text-3xl font-bold">Add User</h1>
      <p className="text-gray-400">Add a new representor</p>
      <hr className="my-5" />
      <span className="font-bold my-10">User Information</span>
      <hr className="pb-10" />
      <div className="grid grid-cols-3 gap-5">
        <form onSubmit={addUserForm.handleSubmit} className="col-span-2">
          <div className="flex flex-col gap-4 w-full">
            <div className="grid grid-cols-2 gap-3">
              <div className="col-span-1">
                <span className="p-float-label w-full">
                  <InputText
                    id="name"
                    name="name"
                    value={addUserForm.values.name}
                    onChange={(e) => {
                      addUserForm.setFieldValue("name", e.target.value);
                    }}
                    className={classNames({
                      "p-invalid": isFormFieldInvalid("name"),
                      "w-full": true,
                    })}
                  />
                  <label htmlFor="name_en">Name</label>
                </span>
                {getFormErrorMessage("name")}
              </div>
              <div className="col-span-1">
                <span className="p-float-label w-full">
                  <Dropdown
                    options={countries}
                    id="country"
                    name="country"
                    optionLabel="name"
                    optionValue="_id"
                    value={addUserForm.values.country}
                    onChange={(e) => {
                      addUserForm.setFieldValue("country", e.value);
                    }}
                    className={classNames({
                      "p-invalid": isFormFieldInvalid("country"),
                      "w-full": true,
                    })}
                  />
                  <label htmlFor="country">Country</label>
                </span>
                {getFormErrorMessage("country")}
              </div>
              <div className="col-span-1">
                <span className="p-float-label w-full">
                  <InputText
                    id="email"
                    name="email"
                    value={addUserForm.values.email}
                    onChange={(e) => {
                      addUserForm.setFieldValue("email", e.target.value);
                    }}
                    className={classNames({
                      "p-invalid": isFormFieldInvalid("email"),
                      "w-full": true,
                    })}
                  />
                  <label htmlFor="email">Email</label>
                </span>
                {getFormErrorMessage("email")}
              </div>
              <div className="col-span-1">
                <span className="p-float-label w-full">
                  <Password
                    id="password"
                    name="password"
                    value={addUserForm.values.password}
                    onChange={(e) => {
                      addUserForm.setFieldValue("password", e.target.value);
                    }}
                    className={classNames({
                      "p-invalid": isFormFieldInvalid("password"),
                      "w-full": true,
                    })}
                    pt={{
                      input: "w-full",
                    }}
                  />
                  <label htmlFor="password">Password</label>
                </span>
                {getFormErrorMessage("password")}
              </div>
            </div>

            <div className="flex flex-col gap-2">
              <Button
                loading={addUserForm.isSubmitting}
                disabled={
                  addUserForm.isSubmitting ||
                  !addUserForm.dirty ||
                  !addUserForm.isValid
                }
                type="submit"
                label="Create User"
              />
            </div>
          </div>
        </form>
        <div className="col-span-1"></div>
      </div>
    </div>
  );
}

export default AddUser;
