import { toast } from "react-toastify";

export const handleResponseError = (axiosError = {}) => {
  if (axiosError.response) {
    if (
      axiosError.response?.status === 401 &&
      window.location.pathname !== "/login"
    ) {
      localStorage.clear();
      sessionStorage.clear();
      window.location.replace("/login");
      toast("Your session has expired. Please login again.", {
        type: "error",
        position: "top-right",
      });
    }

    let error = axiosError?.response?.data?.message;

    if (Array.isArray(error)) {
      error = error.join(", ");
    }

    toast(error, {
      type: "error",
      position: "top-right",
    });
  } else if (axiosError.request) {
    toast("Something went wrong. Please try again later.", {
      type: "error",
      position: "top-right",
    });
  }

  return;
};
