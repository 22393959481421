import { Button } from "primereact/button";
import React from "react";
import { useNavigate } from "react-router-dom";

function Error() {
  const navigate = useNavigate();

  return (
    <div className="w-screen h-screen flex flex-col gap-2 justify-center items-center">
      <h1 className="text-3xl font-bold">
        Something went wrong. Please try again later.
      </h1>
      <Button label="Go home" onClick={() => navigate("/")} />
    </div>
  );
}

export default Error;
