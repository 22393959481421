import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import { UserContext } from "./UserContext";

export const InstitutionsContext = createContext();

export const InstitutionsProvider = ({ children }) => {
  const [institutions, setInstitutions] = useState(null);

  const { user } = useContext(UserContext);

  useEffect(() => {
    if (user && user.role === "REPRESENTOR") {
      axios
        .get("/institution/private/list")
        .then((res) => res.data && setInstitutions(res.data))
        .catch((e) => {});
    }
  }, [user]);

  return (
    <InstitutionsContext.Provider value={{ institutions, setInstitutions }}>
      {children}
    </InstitutionsContext.Provider>
  );
};
