import React, { useEffect, useState } from "react";
import { FaHospitalSymbol } from "react-icons/fa";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { useNavigate } from "react-router-dom";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import axios from "axios";
import {
  INSTITUTIONS_TYPES,
  INSTITUTIONS_TYPES_COLORS,
} from "../../types/institution.types";
import { Badge } from "primereact/badge";
import { Dropdown } from "primereact/dropdown";

function ListAllInstitutions() {
  const navigate = useNavigate();

  const [institutions, setInstitutions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    country: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    region: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    locality: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const actionsBodyTemplate = (rowData) => {
    return (
      <div className="flex align-items-center gap-2">
        <Button
          onClick={() => {
            navigate(`/institutions/${rowData._id}`);
          }}
          severity="primary"
          label="View"
          icon="pi pi-eye"
          size="small"
        />
        <Button
          onClick={handleDeleteButton}
          severity="danger"
          label="Delete"
          icon="pi pi-trash"
          size="small"
          disabled={rowData.isDeleted}
        />
      </div>
    );
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-between">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
            className="p-inputtext-sm"
          />
        </span>
      </div>
    );
  };

  const header = renderHeader();

  const handleDeleteButton = (event) => {
    confirmPopup({
      target: event.currentTarget,
      message: "Do you want to delete this record?",
      icon: "pi pi-info-circle",
      defaultFocus: "reject",
      acceptClassName: "p-button-danger",
      accept: () => {},
      reject: () => {},
    });
  };

  useEffect(() => {
    axios
      .get("/institution/list", {
        params: {
          populateCountry: true,
          populateRegion: true,
          populateLocality: true,
          populateRepresentor: true,
          limit: 9999999,
        },
      })
      .then((res) => {
        setInstitutions(res.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const typeRowFilterTemplate = (options) => {
    return (
      <Dropdown
        options={[
          {
            label: "Hospital",
            value: "HOSPITAL",
          },
          {
            label: "Clinic",
            value: "CLINIC",
          },
          {
            label: "Grooming Center",
            value: "CENTER",
          },
        ]}
        optionLabel="label"
        optionValue="value"
        value={options.value}
        onChange={(e) => options.filterApplyCallback(e.value)}
        placeholder="Select Type"
        className="p-column-filter"
        showClear
        style={{ minWidth: "12rem" }}
      />
    );
  };

  return (
    <div>
      <ConfirmPopup />
      <div className="flex items-center gap-2 w-full justify-between">
        <div className="">
          <div className="flex items-center gap-2">
            <FaHospitalSymbol size={20} />
            <span className="text-3xl font-bold">Institutions</span>
          </div>
          <small className="text-xs text-gray-400">
            List of all institutions
          </small>
        </div>

        <div className="actions"></div>
      </div>

      <DataTable
        value={institutions}
        paginator
        rows={10}
        size="small"
        loading={loading}
        className="text-sm mt-2"
        dataKey="id"
        filters={filters}
        filterDisplay="row"
        globalFilterFields={[
          "name",
          "country.name",
          "region.name",
          "locality.name",
          "type",
        ]}
        header={header}
        rowHover
        emptyMessage="No institutions found."
      >
        <Column
          field="name"
          header="Name"
          filter
          filterPlaceholder="Search by name"
        />
        <Column header="Country" filterField="country" field="country.name" />
        <Column header="Region" filterField="region.name" field="region.name" />
        <Column
          header="Locality"
          filterField="locality.name"
          field="locality.name"
        />
        <Column
          header="Type"
          filterField="type"
          filter
          showFilterMenu={false}
          filterElement={typeRowFilterTemplate}
          field={(rowData) => {
            return (
              <Badge
                value={INSTITUTIONS_TYPES[rowData.type]}
                severity={INSTITUTIONS_TYPES_COLORS[rowData.type]}
              />
            );
          }}
        />
        <Column
          header="Representor"
          filterField="representor.name"
          field="representor.name"
        />
        <Column header="Actions" body={actionsBodyTemplate} />
      </DataTable>
    </div>
  );
}

export default ListAllInstitutions;
