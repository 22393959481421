import axios from "axios";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import React from "react";
import { handleResponseError } from "../../utils/handleResponseError";

function AddDoctor({
  institutionId,
  representorId,
  setModalVisible,
  setDoctors,
  ownResource = false,
}) {
  const addDoctorForm = useFormik({
    initialValues: {
      name: "",
      description: "",
      representor: representorId,
      institution: institutionId,
      availableHours: [{ day: "Sun", from: "07:00", to: "20:00" }],
    },
    validate: (data) => {
      let errors = {};

      let requiredFields = [
        {
          name: "name",
          label: "Name",
        },
      ];

      requiredFields.forEach((field) => {
        if (!data[field.name]) {
          errors[field.name] = `${field.label} is required`;
        }
      });

      if (data.name.length > 50) {
        errors.name = "Name must be less than 50 characters";
      }
      if (data.name.length < 3) {
        errors.name = "Name must be more than 3 characters";
      }

      return errors;
    },
    onSubmit: (data, { setSubmitting }) => {
      if (data) {
        let endpoint;

        if (ownResource) endpoint = "/physician/private";
        if (!ownResource) endpoint = "/physician";

        axios
          .post(endpoint, data)
          .then((res) => {
            setModalVisible(false);
            setDoctors((prev) => [...prev, res.data]);
          })
          .catch((e) => {
            handleResponseError(e);
          })
          .finally(() => {
            setSubmitting(false);
          });
      }
    },
  });

  const isFormFieldInvalid = (name) =>
    !!(addDoctorForm.touched[name] && addDoctorForm.errors[name]);

  const getFormErrorMessage = (name) => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{addDoctorForm.errors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  return (
    <div>
      <span className="font-bold my-10">Doctor Information</span>
      <hr className="pb-10" />
      <div className="grid grid-cols-2 gap-5">
        <form onSubmit={addDoctorForm.handleSubmit} className="col-span-2">
          <div className="flex flex-col gap-4 w-full">
            <div className="flex flex-col gap-2 w-full">
              <div className="grid grid-cols-2 gap-3">
                <div className="col-span-2">
                  <span className="p-float-label w-full">
                    <InputText
                      id="name"
                      name="name"
                      value={addDoctorForm.values.name}
                      onChange={(e) => {
                        addDoctorForm.setFieldValue("name", e.target.value);
                      }}
                      className={classNames({
                        "p-invalid": isFormFieldInvalid("name"),
                        "w-full": true,
                      })}
                    />
                    <label htmlFor="name">Name</label>
                  </span>
                  {getFormErrorMessage("name")}
                </div>
                <div className="col-span-2">
                  <span className="p-float-label w-full">
                    <InputText
                      id="description"
                      name="description"
                      value={addDoctorForm.values.description}
                      onChange={(e) => {
                        addDoctorForm.setFieldValue(
                          "description",
                          e.target.value
                        );
                      }}
                      className={classNames({
                        "p-invalid": isFormFieldInvalid("description"),
                        "w-full": true,
                      })}
                    />
                    <label htmlFor="description">Description</label>
                  </span>
                  {getFormErrorMessage("description")}
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-2">
              <Button
                loading={addDoctorForm.isSubmitting}
                disabled={
                  addDoctorForm.isSubmitting ||
                  !addDoctorForm.dirty ||
                  !addDoctorForm.isValid
                }
                type="submit"
                label="Add Doctor"
              />
            </div>
          </div>
        </form>
        <div className="col-span-1"></div>
      </div>
    </div>
  );
}

export default AddDoctor;
