import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import mapMarkerImage from "../../../assets/images/map-marker.svg";

const defaultCenter = {
  lat: 30.745,
  lng: 31.523,
};

function Map({
  width = "100%",
  height = "400px",
  currentLocation,
  institutionId,
  privateResource = false,
  setInstitution,
}) {
  const { isLoaded } = useJsApiLoader({
    mapIds: ["bfab162a59c71a8e"],
    id: "bfab162a59c71a8e",
    googleMapsApiKey: "AIzaSyBYpxK044g3MKhT_9VcoMknC7l69IQE_xM",
  });

  const [map, setMap] = useState(null);
  const [location, setLocation] = useState(
    currentLocation
      ? { lat: currentLocation?.latitude, lng: currentLocation?.longitude }
      : {
          lat: 29.9537564,
          lng: 31.5370003,
        }
  );

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(
      location ? location : defaultCenter
    );

    map.fitBounds(bounds);

    let marker;

    // eslint-disable-next-line no-undef
    marker = new google.maps.Marker({
      position: location,
      map,
      draggable: true,
      icon: {
        url: mapMarkerImage,
        scaledSize: new window.google.maps.Size(60, 60),
      },
    });

    // eslint-disable-next-line no-undef
    google.maps.event.addListener(marker, "dragend", function (event) {
      if (location) {
        setLocation(event.latLng.toJSON());
        updateLocation(event.latLng.toJSON());

        map.panTo(event.latLng);
        map.setZoom(15);
      }
    });

    setMap(map);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const updateLocation = async (location) => {
    const privateUrl = "institution/private/location/";
    const adminUrl = "institution/location/";

    axios
      .patch(
        privateResource ? privateUrl + institutionId : adminUrl + institutionId,
        {
          location: {
            latitude: location.lat,
            longitude: location.lng,
          },
        }
      )
      .then((res) => {
        toast.success("Location updated successfully");
        setInstitution(res.data);
      });
  };

  return isLoaded ? (
    <GoogleMap
      ref={map}
      mapContainerStyle={{
        width,
        height,
      }}
      options={{
        streetViewControl: false,
        mapTypeControl: false,
        minZoom: 8,
        restriction: {
          latLngBounds: {
            south: 22.0,
            west: 25.0,
            north: 31.7,
            east: 36.0,
          },
        },
      }}
      center={
        currentLocation
          ? {
              lat: currentLocation?.latitude,
              lng: currentLocation?.longitude,
            }
          : defaultCenter
      }
      zoom={8}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {/* <MarkerF
        options={{
          draggable: true,
        }}
        onDragEnd={(e) => setLocation(e.latLng.toJSON())}
        position={location}
      /> */}
      <></>
    </GoogleMap>
  ) : (
    <></>
  );
}

export default Map;
