import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import "./index.css";
import { PrimeReactProvider } from "primereact/api";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primeicons/primeicons.css";
import "react-toastify/dist/ReactToastify.css";
import { UserProvider } from "./contexts/UserContext";
import { InstitutionsProvider } from "./contexts/InstitutionsContext";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <PrimeReactProvider>
    <Suspense fallback={<div>Loading...</div>}>
      <UserProvider>
        <InstitutionsProvider>
          <App />
        </InstitutionsProvider>
      </UserProvider>
    </Suspense>
  </PrimeReactProvider>
);
