import axios from "axios";
import { useFormik } from "formik";
import { Avatar } from "primereact/avatar";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { handleResponseError } from "../../utils/handleResponseError";
import AvatarEditor from "react-avatar-editor";
import { Dialog } from "primereact/dialog";
import { Slider } from "primereact/slider";
import { Divider } from "primereact/divider";
import Map from "../components/map/Map";

function EditInstitution() {
  const params = useParams();

  const avatarEditorRef = useRef(null);

  const [institution, setInstitution] = useState(null);
  const [loading, setLoading] = useState(true);

  const [showMap, setShowMap] = useState(false);

  const [countries, setCountries] = useState([]);
  const [regions, setRegions] = useState([]);
  const [localities, setLocalities] = useState([]);

  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageSubmitLoading, setImageSubmitLoading] = useState(false);
  const [zoom, setZoom] = useState(1);

  const editInstitutionNameForm = useFormik({
    initialValues: {
      name: "",
    },
    onSubmit: (values, { setSubmitting }) => {
      axios
        .patch(`/institution/name/${params._id}`, values)
        .catch((e) => handleResponseError(e))
        .finally(() => setSubmitting(false));
    },
  });

  const editInstitutionAddressForm = useFormik({
    initialValues: {
      country: "",
      region: "",
      locality: "",
      line1: "",
    },
    onSubmit: (values, { setSubmitting }) => {
      axios
        .patch(`/institution/address/${params._id}`, values)
        .catch((e) => handleResponseError(e))
        .finally(() => setSubmitting(false));
    },
  });

  const editInstitutionTypeForm = useFormik({
    initialValues: {
      type: "",
    },
    onSubmit: (values, { setSubmitting }) => {
      axios
        .patch(`/institution/type/${params._id}`, values)
        .catch((e) => handleResponseError(e))
        .finally(() => setSubmitting(false));
    },
  });

  useEffect(() => {
    axios.get("/region/list?type=COUNTRY").then((res) => {
      setCountries(res.data);

      if (res.data?.length) {
        axios
          .get(`/institution/${params._id}`)
          .then((res) => {
            setInstitution(res.data);
          })
          .catch((e) => handleResponseError(e))
          .finally(() => setLoading(false));
      }
    });
  }, [params._id]);

  useEffect(() => {
    if (institution) {
      editInstitutionNameForm.setFieldValue("name", institution.name);
      editInstitutionAddressForm.setFieldValue(
        "country",
        institution.country._id
      );
      editInstitutionAddressForm.setFieldValue(
        "region",
        institution.region._id
      );
      editInstitutionAddressForm.setFieldValue(
        "locality",
        institution.locality._id
      );
      editInstitutionAddressForm.setFieldValue("line1", institution.line1);
      editInstitutionTypeForm.setFieldValue("type", institution.type);
    }
    // eslint-disable-next-line
  }, [institution]);

  useEffect(() => {
    if (editInstitutionAddressForm.values.country) {
      axios
        .get(
          `/region/list?type=REGION&parents=${editInstitutionAddressForm.values.country}`
        )
        .then((res) =>
          setRegions(
            res.data.sort((a, b) => {
              const nameA = a.name.toLowerCase();
              const nameB = b.name.toLowerCase();

              if (nameA < nameB) return -1;
              if (nameA > nameB) return 1;
              return 0;
            })
          )
        );
    }
  }, [editInstitutionAddressForm.values.country]);

  useEffect(() => {
    if (editInstitutionAddressForm.values.region) {
      axios
        .get(
          `/region/list?type=LOCALITY&parents=${editInstitutionAddressForm.values.region}`
        )
        .then((res) =>
          setLocalities(
            res.data.sort((a, b) => {
              const nameA = a.name.toLowerCase();
              const nameB = b.name.toLowerCase();

              if (nameA < nameB) return -1;
              if (nameA > nameB) return 1;
              return 0;
            })
          )
        );
    }
  }, [editInstitutionAddressForm.values.region]);

  const institutionTypes = [
    {
      label: "Hospital",
      value: "HOSPITAL",
    },
    {
      label: "Clinic",
      value: "CLINIC",
    },
    {
      label: "Grooming Center",
      value: "CENTER",
    },
  ];

  const onAvatarUpload = (event) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedFile(event.target.files[0]);
      setIsDialogVisible(true);
    }
  };

  const handleCloseDialog = () => {
    setIsDialogVisible(false);
    setSelectedFile(null);
    setImageSubmitLoading(false);
  };

  const updateAvatar = () => {
    setImageSubmitLoading(true);

    const canvas = avatarEditorRef.current.getImageScaledToCanvas();

    canvas.toBlob((blob) => {
      const formData = new FormData();
      formData.append("avatar", blob);

      axios
        .patch(`/institution/avatar/${params._id}`, formData)
        .then(({ data }) => {
          setInstitution(data);
        })
        .catch((e) => handleResponseError(e))
        .finally(() => handleCloseDialog());
    });
  };

  return (
    <div className="flex flex-col gap-10">
      <div className="flex flex-col gap-2">
        <h1 className="text-2xl font-semibold text-gray-800">
          Edit Institution
        </h1>
        <small className="text-xs text-gray-400">
          Edit institution information
        </small>
      </div>

      {loading ? (
        <div className="w-full mt-5 flex justify-center">
          <ProgressSpinner />
        </div>
      ) : (
        <>
          <Panel header="Avatar">
            <div className="flex gap-2 items-center">
              {institution?.avatar && (
                <Avatar
                  image={institution?.avatar}
                  className="w-[150px] h-[150px]"
                />
              )}
              <input type="file" onChange={onAvatarUpload} accept="image/*" />
              <Dialog
                visible={isDialogVisible}
                onHide={handleCloseDialog}
                header="Edit Avatar"
                modal
                breakpoints={{ "960px": "75vw", "640px": "100vw" }}
                style={{ width: "50vw" }}
              >
                {selectedFile && (
                  <>
                    <div className="flex flex-col gap-4">
                      <div className="flex w-full justify-center">
                        <AvatarEditor
                          ref={avatarEditorRef}
                          image={URL.createObjectURL(selectedFile)}
                          height={250}
                          width={250}
                          border={50}
                          color={[255, 255, 255, 0.6]}
                          scale={zoom}
                        />
                      </div>
                      <div className="flex w-full justify-center">
                        <Slider
                          value={zoom}
                          onChange={(e) => setZoom(e.value)}
                          min={-1}
                          max={2}
                          step={0.01}
                          style={{ width: "50%" }}
                        />
                      </div>
                      <div className="flex w-full justify-end">
                        <Button
                          label="Save"
                          loading={imageSubmitLoading}
                          onClick={updateAvatar}
                        />
                      </div>
                    </div>
                  </>
                )}
              </Dialog>
            </div>
          </Panel>
          <Panel header="Name">
            <form
              onSubmit={editInstitutionNameForm.handleSubmit}
              className="flex gap-2"
            >
              <InputText
                type="text"
                className="input"
                value={editInstitutionNameForm.values.name}
                onChange={(e) =>
                  editInstitutionNameForm.setFieldValue("name", e.target.value)
                }
              />
              <Button
                disabled={
                  editInstitutionNameForm.isSubmitting ||
                  !editInstitutionNameForm.dirty
                }
                loading={editInstitutionNameForm.isSubmitting}
                type="submit"
                label="Save"
              />
            </form>
          </Panel>
          <Panel header="Type">
            <form
              onSubmit={editInstitutionTypeForm.handleSubmit}
              className="flex gap-2"
            >
              <Dropdown
                options={institutionTypes}
                type="text"
                className="input"
                value={editInstitutionTypeForm.values.type}
                onChange={(e) =>
                  editInstitutionTypeForm.setFieldValue("type", e.target.value)
                }
              />
              <Button
                disabled={
                  editInstitutionTypeForm.isSubmitting ||
                  !editInstitutionTypeForm.dirty
                }
                loading={editInstitutionTypeForm.isSubmitting}
                type="submit"
                label="Save"
              />
            </form>
          </Panel>
          <Panel header="Location">
            <div className="flex flex-col gap-2">
              <form
                onSubmit={editInstitutionAddressForm.handleSubmit}
                className="grid grid-cols-4 w-full gap-10 py-5"
              >
                <span className="p-float-label col-span-1">
                  <Dropdown
                    className="w-full"
                    options={countries}
                    optionLabel="name"
                    optionValue="_id"
                    value={editInstitutionAddressForm.values.country}
                    onChange={(e) =>
                      editInstitutionAddressForm.setFieldValue(
                        "country",
                        e.target.value
                      )
                    }
                  />
                  <label htmlFor="country">Country</label>
                </span>

                <span className="p-float-label col-span-1">
                  <Dropdown
                    className="w-full"
                    options={regions}
                    optionLabel="name"
                    optionValue="_id"
                    filter
                    value={editInstitutionAddressForm.values.region}
                    onChange={(e) =>
                      editInstitutionAddressForm.setFieldValue(
                        "region",
                        e.target.value
                      )
                    }
                  />
                  <label htmlFor="region">Region</label>
                </span>

                <span className="p-float-label col-span-1">
                  <Dropdown
                    className="w-full"
                    options={localities}
                    optionLabel="name"
                    optionValue="_id"
                    filter
                    value={editInstitutionAddressForm.values.locality}
                    onChange={(e) =>
                      editInstitutionAddressForm.setFieldValue(
                        "locality",
                        e.target.value
                      )
                    }
                  />
                  <label htmlFor="locality">Locality</label>
                </span>

                <span className="p-float-label col-span-3">
                  <InputText
                    type="text"
                    className="input w-full"
                    value={editInstitutionAddressForm.values.line1}
                    onChange={(e) =>
                      editInstitutionAddressForm.setFieldValue(
                        "line1",
                        e.target.value
                      )
                    }
                  />
                </span>

                <Button
                  disabled={
                    editInstitutionAddressForm.isSubmitting ||
                    !editInstitutionAddressForm.dirty
                  }
                  loading={editInstitutionAddressForm.isSubmitting}
                  type="submit"
                  label="Save"
                />
              </form>
              <div id="googleLocation" className="flex flex-col gap-2">
                <Divider />
                <div className="flex w-full justify-between items-center">
                  <span className="text-lg font-semibold">
                    {institution?.location?.coordinates
                      ? "Location is set"
                      : "Location is not set"}{" "}
                  </span>
                  <div id="actions" className="flex gap-2">
                    <Button
                      label="Edit Location"
                      text={showMap}
                      onClick={() => setShowMap(!showMap)}
                    />
                  </div>
                </div>
                {showMap && (
                  <Map
                    currentLocation={institution?.location?.coordinates}
                    institutionId={institution._id}
                    privateResource={false}
                    setInstitution={setInstitution}
                  />
                )}
              </div>
            </div>
          </Panel>
        </>
      )}
    </div>
  );
}

export default EditInstitution;
