import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { ErrorBoundary } from "react-error-boundary";
import { setupAxios } from "./services/axios";
import { ToastContainer } from "react-toastify";

import Login from "./public-space/auth/Login";
import ForgetPassword from "./public-space/auth/ForgetPassword";
import DashboardLayout from "./private-space/dashboard/DashboardLayout";

import RequireAuth from "./guards/RequireAuth";
import RequireNoAuth from "./guards/RequireNoAuth";

import Error from "./shared/Error";

import ViewDoctor from "./private-space/doctors/ViewDoctor";
import AddDoctor from "./private-space/doctors/AddDoctor";
import UserSettings from "./private-space/settings/UserSettings";
import ListAllUsers from "./private-space/users/ListAllUsers";
import ViewUser from "./private-space/users/ViewUser";
import NotFound from "./private-space/shared/NotFound";
import ListAllBookings from "./private-space/bookings/ListAllBookings";
import EditInstitution from "./private-space/institutions/EditInstitution";
import ViewInstitution from "./private-space/institutions/ViewInstitution";
import AddInstitution from "./private-space/institutions/AddInstitution";
import ListAllInstitutions from "./private-space/institutions/ListAllInstitutions";
import DashboardHome from "./private-space/dashboard/DashboardHome";
import AddUser from "./private-space/users/AddUser";
import ViewOwnInstitution from "./private-space/institutions/ViewOwnInstitution";
import ViewOwnDoctor from "./private-space/doctors/ViewOwnDoctor";
import EditOwnInstitution from "./private-space/institutions/EditOwnInstitution";
import ListRepBookings from "./private-space/bookings/ListRepBookings";

function App() {
  setupAxios();

  return (
    <ErrorBoundary fallback={<Error />}>
      <ToastContainer />
      <Router>
        <Routes>
          <Route element={<RequireNoAuth />}>
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgetPassword />} />
          </Route>

          <Route element={<RequireAuth />}>
            <Route path="/" element={<DashboardLayout />}>
              <Route path="" element={<DashboardHome />} />
              <Route path="/institutions" element={<ListAllInstitutions />} />
              <Route path="/institutions/:_id" element={<ViewInstitution />} />
              <Route
                path="/institutions/private/:_id"
                element={<ViewOwnInstitution />}
              />
              <Route
                path="/institutions/edit/:_id"
                element={<EditInstitution />}
              />
              <Route
                path="/institutions/own/edit/:_id"
                element={<EditOwnInstitution />}
              />
              <Route
                path="/institutions/add/:userId"
                element={<AddInstitution />}
              />
              <Route
                path="/doctor/add/:institutionId"
                element={<AddDoctor />}
              />
              <Route path="/doctor/:doctorId" element={<ViewDoctor />} />
              <Route path="/doctor/own/:doctorId" element={<ViewOwnDoctor />} />
              <Route path="/users" element={<ListAllUsers />} />
              <Route path="/users/add" element={<AddUser />} />
              <Route path="/users/:_id" element={<ViewUser />} />
              <Route path="/user-settings" element={<UserSettings />} />
              <Route path="/bookings" element={<ListAllBookings />} />
              <Route path="/rep-bookings" element={<ListRepBookings />} />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </Router>
    </ErrorBoundary>
  );
}

export default App;
