import axios from "axios";
import { FilterMatchMode } from "primereact/api";
import { Column } from "primereact/column";
import { ConfirmPopup } from "primereact/confirmpopup";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
import { FaTicket } from "react-icons/fa6";
import { toast } from "react-toastify";
import {
  bookingPaymentStatuses,
  bookingStatuses,
  renderBookingPaymentStatus,
  renderBookingPaymentStatusBackground,
  renderBookingStatus,
  renderBookingStatusBackground,
} from "../../types/booking.types";
import { Badge } from "primereact/badge";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import moment from "moment/moment";
import { Sidebar } from "primereact/sidebar";
import ViewBookingDetails from "./components/ViewBookingDetails";

function ListAllBookings() {
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [viewBooking, setViewBooking] = useState(null);
  const [isViewBookingVisible, setIsViewBookingVisible] = useState(false);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    status: { value: null, matchMode: FilterMatchMode.EQUALS },
    paymentStatus: { value: null, matchMode: FilterMatchMode.EQUALS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-between">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
            className="p-inputtext-sm"
          />
        </span>
      </div>
    );
  };

  const header = renderHeader();

  const handleBookingPaid = (bookingId) => {
    axios
      .patch(`/schedule/payment-status/paid/${bookingId}`, {
        params: {
          sortByTime: -1,
        },
      })
      .then((res) => {
        toast.success(res.data.message);

        let _bookings = [...bookings];
        let updatedItemIndex = _bookings.findIndex(
          (booking) => booking._id === res.data._id
        );
        _bookings[updatedItemIndex] = res.data;

        setBookings(_bookings);
      })
      .catch((e) => {
        toast.error("Error Updating Status");
      });
  };

  const totalBody = (rowData) => {
    return (
      <span>
        {new Intl.NumberFormat("en-EG", {
          style: "currency",
          currency: "EGP",
          minimumFractionDigits: 2,
        }).format(rowData.total)}
      </span>
    );
  };

  useEffect(() => {
    axios
      .get("/schedule/list", {
        params: {
          populateUser: true,
          sortByTime: -1,
          limit: 9999999,
        },
      })
      .then((res) => {
        setBookings(res.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const statusesFilterTemplate = (options) => {
    return (
      <Dropdown
        options={bookingStatuses}
        optionLabel="label"
        optionValue="value"
        value={options.value}
        onChange={(e) => options.filterApplyCallback(e.value)}
        placeholder="Select Status"
        className="p-column-filter"
        showClear
      />
    );
  };

  const paymentStatusesFilterTemplate = (options) => {
    return (
      <Dropdown
        options={bookingPaymentStatuses}
        optionLabel="label"
        optionValue="value"
        value={options.value}
        onChange={(e) => options.filterApplyCallback(e.value)}
        placeholder="Select Payment Status"
        className="p-column-filter"
        showClear
      />
    );
  };

  return (
    <div>
      <div>
        <ConfirmPopup />
        <div className="flex items-center gap-2 w-full justify-between">
          <div className="">
            <div className="flex items-center gap-2">
              <FaTicket size={20} />
              <span className="text-3xl font-bold">Bookings</span>
            </div>
            <small className="text-xs text-gray-400">
              List of all bookings
            </small>
          </div>

          <div className="actions"></div>
        </div>

        <DataTable
          value={bookings}
          paginator
          rows={10}
          size="small"
          loading={loading}
          className="text-sm mt-2"
          dataKey="_id"
          filters={filters}
          filterDisplay={"row"}
          globalFilterFields={[
            "institution.name",
            "total",
            "user.name",
            "paymentStatus",
            "status",
          ]}
          header={header}
          rowHover
          onRowClick={(row) => {
            setViewBooking(row.data._id);
            setIsViewBookingVisible(true);
          }}
          emptyMessage="No bookings found."
        >
          <Column
            header="Date"
            field="time"
            body={(rowData) => {
              return (
                <>{moment(rowData.time, "X").format("DD-MM-YYYY HH:mm")}</>
              );
            }}
          />
          <Column header="Institution" field="institution.name" />
          <Column header="Client" field="user.name" />
          <Column header="Total" field="total" body={totalBody} />
          <Column
            header="Status"
            field="status"
            filterField="status"
            filter
            showFilterMenu={false}
            filterElement={statusesFilterTemplate}
            body={(rowData) => {
              return (
                <Badge
                  severity={renderBookingStatusBackground(
                    rowData.paymentStatus
                  )}
                  value={renderBookingStatus(rowData.status)}
                />
              );
            }}
          />
          <Column
            header="Payment Status"
            field="paymentStatus"
            filterField="paymentStatus"
            filter
            showFilterMenu={false}
            filterElement={paymentStatusesFilterTemplate}
            body={(rowData) => {
              return (
                <Badge
                  severity={renderBookingPaymentStatusBackground(
                    rowData.paymentStatus
                  )}
                  value={renderBookingPaymentStatus(rowData.paymentStatus)}
                />
              );
            }}
          />
          <Column
            header="Actions"
            body={(rowData) => {
              return (
                <>
                  {rowData.paymentStatus !== "PAID" && (
                    <Button
                      className="p-button-text"
                      severity="success"
                      size={"small"}
                      label="Mark as Paid"
                      icon="pi pi-check"
                      onClick={() => handleBookingPaid(rowData._id)}
                    />
                  )}
                </>
              );
            }}
          />
        </DataTable>
        <Sidebar
          visible={isViewBookingVisible}
          position="right"
          style={{
            minWidth: 500,
          }}
          onHide={() => setIsViewBookingVisible(false)}
        >
          <ViewBookingDetails bookingId={viewBooking} ownResource={false} />
        </Sidebar>
      </div>
    </div>
  );
}

export default ListAllBookings;
