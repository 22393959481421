export const animalTypes = [
  {
    label: "Dog",
    value: "DOG",
  },
  {
    label: "Cat",
    value: "CAT",
  },
  {
    label: "Bird",
    value: "BIRD",
  },
];

export const DogBreed = [
  "Bichon Frise",
  "Biewer Terrier",
  "Boxers",
  "Bulldog",
  "Beagle",
  "Chihuahua",
  "Dalmatian",
  "Doberman pinscher",
  "German Shepherd",
  "German Spitz",
  "Golden Retriever",
  "Griffon",
  "Labrador retriever",
  "Miniature schnauzer",
  "Jack Russell Terrier",
  "Poodle",
  "Pekingese",
  "Pomchi",
  "Pug",
  "Pomeranian",
  "Rottweiler",
  "Yorkshire Terrier",
  "Boxer",
  "Dachshund",
  "Shichon",
  "Shih Tzu",
  "Siperian Husky",
  "Yorkshire terrier",
  "Other",
];

export const CatBreed = [
  "Abyssinian",
  "American shorthair",
  "Birman",
  "British Shorthair",
  "Bengal",
  "Bombay",
  "Cornish rex",
  "Devon Rex",
  "Domestic Shorthair",
  "Exotic shorthair",
  "Himalayan",
  "Maine Coon",
  "Norwegian forest cat",
  "Oriental",
  "Persian",
  "Ragdoll",
  "Russian blue",
  "Siberian",
  "Sphynx",
  "Siamese",
  "Scottish Fold",
  "Selkirk rex",
  "Other",
];

export const BirdBreed = [
  "Cockatiel",
  "Budgerigar",
  "African Grey Parrot",
  "Lovebird",
  "Conure",
  "Cockatoo",
  "Dove",
  "Eclectus Parrot",
  "Macaw",
  "Hyacinth Macaaw",
  "Amazon Parrot",
  "Green-Cheeked Conure",
  "Hahn's Macaw",
  "Parakeet",
  "Parrotlet",
  "Canary",
  "Finch",
  "Quaker",
  "Umbrella Cockatoo",
  "Other",
];

export const animalGenderOptions = [
  {
    label: "Male",
    value: "MALE",
  },
  {
    label: "Female",
    value: "FEMALE",
  },
];

export const animalHairOptions = [
  {
    label: "Long",
    value: "LONG",
  },
  {
    label: "Short",
    value: "SHORT",
  },
];
