import axios from "axios";
import { Avatar } from "primereact/avatar";
import { FileUpload } from "primereact/fileupload";
import React, { useContext } from "react";
import { toast } from "react-toastify";
import { UserContext } from "../../../contexts/UserContext";
import { handleResponseError } from "../../../utils/handleResponseError";

function ChangeAvatar() {
  const { user, setUser } = useContext(UserContext);

  const onAvatarUpload = (event) => {
    const file = event.files[0];

    axios
      .patch(
        "/user/private/avatar",
        {
          avatar: file,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        setUser(res.data);
        toast("Avatar changed successfully", {
          type: "success",
        });
      })
      .catch((e) => {
        handleResponseError(e);
      });
  };

  return (
    <div className="flex flex-col gap-7 mt-5">
      <div className="grid grid-cols-2 gap-4">
        <div className="col-span-2 md:col-span-1">
          <div className="flex flex-col md:flex-row items-center gap-5">
            <Avatar
              image={user?.avatar}
              label={user?.name?.charAt(0)}
              size={"xlarge"}
              shape="circle"
              className="w-[150px] h-[150px] md:w-[100px] md:h-[100px]"
            />
            <FileUpload
              mode="basic"
              name="avatar"
              accept="image/*"
              maxFileSize={1000000}
              uploadHandler={onAvatarUpload}
              chooseLabel="Choose Avatar"
              customUpload
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangeAvatar;
