import React, { useContext, useState } from "react";
import Logo from "../../../assets/images/logo.jpeg";
import { FaBars, FaTimesCircle } from "react-icons/fa";
import { Sidebar } from "primereact/sidebar";
import { PiHouseFill, PiTicketFill, PiUserFill } from "react-icons/pi";
import { FaHospitalSymbol } from "react-icons/fa";
import { NavLink, useNavigate } from "react-router-dom";
import { UserContext } from "../../../contexts/UserContext";
import { InstitutionsContext } from "../../../contexts/InstitutionsContext";

function MobileHeader() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const navigate = useNavigate();

  const { user, setUser } = useContext(UserContext);
  const { setInstitutions } = useContext(InstitutionsContext);

  const handleLogout = () => {
    localStorage.removeItem("token");
    setUser(null);
    setInstitutions([]);
    navigate("/login");
  };

  const menuOptions = [
    {
      entries: [
        {
          icon: <PiHouseFill className="text-md text-slate-900" />,
          label: "Home",
          to: "/",
          allowedRoles: ["ADMIN", "REPRESENTOR"],
        },
        {
          icon: <FaHospitalSymbol className="text-md text-slate-900" />,
          label: "Institutions",
          to: "/institutions",
          allowedRoles: ["ADMIN"],
        },
        {
          icon: <PiUserFill className="text-md text-slate-600" />,
          label: "Users",
          to: "/users",
          allowedRoles: ["ADMIN"],
        },
        {
          icon: <PiTicketFill className="text-md text-slate-600" />,
          label: "Bookings",
          to: "/bookings",
          allowedRoles: ["ADMIN"],
        },
      ],
    },
  ];

  const NavbarItem = ({ icon, label, to }) => {
    return (
      <NavLink
        to={to}
        onClick={() => setIsMenuOpen(false)}
        className={({ isActive }) =>
          isActive
            ? "menu-option flex gap-2 items-center font-[700] py-2 px-2 rounded-full bg-gray-100 hover:bg-gray-200 hover:cursor-pointer transition-all ease-in-out duration-200"
            : "menu-option flex gap-2 items-center font-[700] py-2 px-2 rounded-full  hover:bg-gray-200 hover:cursor-pointer transition-all ease-in-out  duration-200"
        }
      >
        {icon}
        <span className="text-md  text-slate-900">{label}</span>
      </NavLink>
    );
  };

  return (
    <>
      <div className="visible md:hidden bg-white border-b-2 py-5 px-4 flex justify-between items-center">
        <img src={Logo} alt="logo" className="w-12 h-12" />
        <FaBars
          onClick={() => setIsMenuOpen(true)}
          className="text-2xl text-orange-500 hover:cursor-pointer"
        />
      </div>
      <Sidebar
        visible={isMenuOpen}
        onHide={() => setIsMenuOpen(false)}
        fullScreen
        showCloseIcon={false}
      >
        <div className="flex w-full justify-end">
          <FaTimesCircle
            onClick={() => setIsMenuOpen(false)}
            className="text-2xl text-orange-500 hover:cursor-pointer"
          />
        </div>
        <div className="mt-5 pb-2 border-b-2">
          {menuOptions.map((section, index) => (
            <React.Fragment key={index}>
              {section.entries.map((entry, index) => (
                <React.Fragment key={index}>
                  {entry.allowedRoles?.includes(user?.role) && (
                    <NavbarItem
                      key={entry.to}
                      icon={entry.icon}
                      label={entry.label}
                      to={entry.to}
                    />
                  )}
                </React.Fragment>
              ))}
              {index !== menuOptions.length - 1 && (
                <hr className="border-slate-200" />
              )}
            </React.Fragment>
          ))}
        </div>
        <div className="mt-5">
          <NavLink onClick={() => setIsMenuOpen(false)} to="/user-settings">
            <div className="flex gap-2 items-center font-[700] py-2 px-2 rounded-full  hover:cursor-pointer transition-all ease-in-out  duration-200">
              <span className="text-md  text-slate-900">Settings</span>
            </div>
          </NavLink>
          <div
            onClick={handleLogout}
            className="flex gap-2 items-center font-[700] py-2 px-2 rounded-full  hover:cursor-pointer transition-all ease-in-out  duration-200"
          >
            <span className="text-md  text-slate-900">Logout</span>
          </div>
        </div>
      </Sidebar>
    </>
  );
}

export default MobileHeader;
