import axios from "axios";
import { Avatar } from "primereact/avatar";
import { Button } from "primereact/button";
import { Rating } from "primereact/rating";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  INSTITUTIONS_TYPES,
  INSTITUTIONS_TYPES_COLORS,
  petServicesOptions,
} from "../../types/institution.types";
import { Badge } from "primereact/badge";
import { Dialog } from "primereact/dialog";
import AddDoctor from "../doctors/AddDoctor";
import { TabPanel, TabView } from "primereact/tabview";
import AddInstitutionPetService from "../pet-services/AddInstitutionPetService";
import { UserContext } from "../../contexts/UserContext";
import { handleResponseError } from "../../utils/handleResponseError";
import {
  bookingPaymentStatuses,
  bookingStatuses,
  renderBookingPaymentStatus,
  renderBookingPaymentStatusBackground,
  renderBookingStatus,
  renderBookingStatusBackground,
} from "../../types/booking.types";
import { toast } from "react-toastify";
import moment from "moment/moment";
import { FilterMatchMode } from "primereact/api";
import { Dropdown } from "primereact/dropdown";
import EditInstitutionSchedule from "./components/EditInstitutionSchedule";
import { Sidebar } from "primereact/sidebar";
import ViewBookingDetails from "../bookings/components/ViewBookingDetails";
import AddRescueAnimal from "../pet-services/AddRescueAnimal";

function ViewOwnInstitution() {
  const navigate = useNavigate();
  const params = useParams();

  const { user } = useContext(UserContext);

  const [institution, setInstitution] = useState(null);
  const [doctors, setDoctors] = useState([]);
  const [services, setServices] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [rescueAnimals, setRescueAnimals] = useState([]);

  const [isDoctorAddVisible, setIsDoctorAddVisible] = useState(false);
  const [isPetServiceAddVisible, setIsPetServiceAddVisible] = useState(false);
  const [isInstitutionScheduleVisible, setIsIsntitutionScheduleVisible] =
    useState(false);
  const [isAddAnimalVisible, setIsAddAnimalVisible] = useState(false);
  const [viewBooking, setViewBooking] = useState(null);
  const [isViewBookingVisible, setIsViewBookingVisible] = useState(false);

  const [filters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    status: { value: null, matchMode: FilterMatchMode.EQUALS },
    paymentStatus: { value: null, matchMode: FilterMatchMode.EQUALS },
  });

  const doctorsActionsBodyTemplate = (rowData) => {
    return (
      <div className="flex align-items-center gap-2">
        <Button
          onClick={() => {
            navigate(`/doctor/own/${rowData._id}`);
          }}
          severity="primary"
          size={"small"}
          label="View"
          icon="pi pi-eye"
          className="p-button-text"
        />
        <Button
          className="p-button-text"
          severity="danger"
          size={"small"}
          label="Delete"
          icon="pi pi-trash"
          onClick={() => handleDoctorDelete(rowData._id)}
        />
      </div>
    );
  };

  const servicesActionsBodyTemplate = (rowData) => {
    return (
      <div className="flex align-items-center gap-2">
        <Button
          className="p-button-text"
          severity="danger"
          size={"small"}
          label="Delete"
          icon="pi pi-trash"
          onClick={() => handleServiceDelete(rowData._id)}
        />
      </div>
    );
  };

  const servicePriceBodyTemplate = (rowData) => {
    return (
      <div className="flex gap-2">
        {rowData?.offers?.[0].price + " " + rowData.offers[0].priceCurrency}
      </div>
    );
  };

  const dayColumnTemplate = (rowData) => {
    return (
      <span>{rowData.day.charAt(0).toUpperCase() + rowData.day.slice(1)}</span>
    );
  };

  const fromColumnTemplate = (rowData) => {
    return <span>{rowData.from ? rowData.from : "N/A"}</span>;
  };

  const toColumnTemplate = (rowData) => {
    return <span>{rowData.to ? rowData.to : "N/A"}</span>;
  };

  const serviceTypeBodyTemplate = (rowData) => {
    const serviceTypes = [...petServicesOptions];
    const service = serviceTypes.find(
      (service) => service.value === rowData.type
    );
    return <span>{service.label}</span>;
  };

  const bookingsActionsBodyTemplate = (rowData) => {
    return (
      <div className="flex align-items-center gap-2">
        {rowData.status === "BOOKED" && (
          <>
            {" "}
            <Button
              className="p-button-text"
              severity="success"
              size={"small"}
              label="Mark as completed"
              icon="pi pi-check"
              onClick={() => handleBookingComplete(rowData._id)}
            />
            <Button
              className="p-button-text"
              severity="danger"
              size={"small"}
              label="Cancel Appointment"
              icon="pi pi-check"
              onClick={() => handleBookingCancel(rowData._id)}
            />
          </>
        )}
      </div>
    );
  };

  useEffect(() => {
    axios
      .get(`/institution/private/${params._id}`)
      .then((res) => {
        res.data && setInstitution(res.data);
      })
      .catch((e) => handleResponseError(e));
  }, [params._id]);

  useEffect(() => {
    if (institution) {
      axios
        .get(`/service/private/list`, {
          params: {
            institution: institution._id,
          },
        })
        .then((res) => {
          setServices(res.data);
        })
        .catch((e) => {});

      axios
        .get("/appointment/private/list", {
          params: {
            institution: institution._id,
            populateAnimal: true,
            populateInstitution: true,
            populateUser: true,
            sortByTime: -1,
          },
        })
        .then((res) => {
          setBookings(res.data);
        })
        .catch((e) => {});
    }
  }, [institution]);

  useEffect(() => {
    if (institution && ["CLINIC", "HOSPITAL"].includes(institution.type)) {
      axios
        .get("/physician/private/list", {
          params: {
            institution: [params._id],
          },
        })
        .then((res) => {
          res.data && setDoctors(res.data);
        })
        .catch((e) => {});
    }
  }, [institution, params._id]);

  useEffect(() => {
    if (institution && institution.inRescueProgram) {
      axios
        .get("/animal/public/rescue-program/list", {
          params: {
            institution: [params._id],
          },
        })
        .then((res) => {
          res.data && setRescueAnimals(res.data);
        })
        .catch((e) => {});
    }
  }, [institution, params._id]);

  const handleServiceDelete = (serviceId) => {
    axios
      .delete(`/service/private/${serviceId}`)
      .then((res) => {
        setServices(services.filter((service) => service._id !== serviceId));
      })
      .catch((e) => handleResponseError(e));
  };

  const handleDoctorDelete = (doctorId) => {
    axios
      .delete(`/physician/private/${doctorId}`)
      .then((res) => {
        setDoctors(doctors.filter((doctor) => doctor._id !== doctorId));
      })
      .catch((e) => handleResponseError(e));
  };

  const handleBookingComplete = (boookingId) => {
    axios
      .patch(`/appointment/private/status/complete/${boookingId}`)
      .then((res) => {
        toast("Booking Status updated", {
          type: "success",
        });

        setBookings(
          bookings.map((booking) => {
            if (booking._id === boookingId) {
              booking.status = "COMPLETED";
            }
            return booking;
          })
        );
      })
      .catch((e) => {
        toast("Error Updating Status", {
          type: "error",
        });
      });
  };

  const handleBookingCancel = (boookingId) => {
    axios
      .patch(`/appointment/private/status/cancel/${boookingId}`)
      .then((res) => {
        toast("Booking Status updated", {
          type: "success",
        });

        setBookings(
          bookings.map((booking) => {
            if (booking._id === boookingId) {
              booking.status = "CANCELLED";
            }
            return booking;
          })
        );
      })
      .catch((e) => {
        toast("Error Updating Status", {
          type: "error",
        });
      });
  };

  const statusesFilterTemplate = (options) => {
    return (
      <Dropdown
        options={bookingStatuses}
        optionLabel="label"
        optionValue="value"
        value={options.value}
        onChange={(e) => options.filterApplyCallback(e.value)}
        placeholder="Select Status"
        className="p-column-filter"
        showClear
      />
    );
  };

  const paymentStatusesFilterTemplate = (options) => {
    return (
      <Dropdown
        options={bookingPaymentStatuses}
        optionLabel="label"
        optionValue="value"
        value={options.value}
        onChange={(e) => options.filterApplyCallback(e.value)}
        placeholder="Select Payment Status"
        className="p-column-filter"
        showClear
      />
    );
  };

  const inRescueProgram = institution ? institution.inRescueProgram : false;

  const inMobileProgram = institution ? institution.inMobileProgram : false;

  return (
    <div>
      {institution ? (
        <>
          <div className="flex flex-col md:flex-row justify-center md:justify-between w-full">
            <div className="flex justify-center md:justify-start items-center gap-2">
              <Avatar
                image={institution.avatar ? institution.avatar : null}
                label={institution.name.charAt(0)}
                size="xlarge"
                className="border-2 border-gray-50 min-w-[4rem] min-h-[4rem] md:min-w-[10rem] md:min-h-[10rem] rounded-md"
              />
              <div className="flex flex-col gap-2">
                <h1 className="text-2xl font-semibold text-gray-800">
                  {institution.name}
                </h1>
                <Rating
                  value={institution.rating ? institution.rating : 0}
                  readOnly
                  stars={5}
                  cancel={false}
                />
              </div>
            </div>
            <div className="flex flex-col md:flex-row mt-6 md:mt-0 items-center gap-2">
              <Button
                onClick={() => {
                  navigate(`/institutions/own/edit/${institution._id}/`);
                }}
                className="p-button-text"
                label="Edit Information"
                icon="pi pi-pencil"
              />
            </div>
          </div>
          <div className="grid grid-cols-3 gap-10 mt-10">
            <div className="col-span-3 md:col-span-3  bg-gray-50 p-5 rounded-md">
              <div className="flex items-center justify-between">
                <span className="text-gray-800 font-bold">
                  General Information
                </span>
              </div>
              <hr className="mb-5" />
              <div className="grid grid-cols-2 gap-3">
                <div className="flex flex-col gap-1 col-span-2">
                  <span className="text-gray-600 text-xs">Address</span>{" "}
                  <span className="text-gray-800 text-md font-semibold">
                    {institution.line1}
                  </span>
                </div>
                <div className="flex flex-col gap-1 col-span-1">
                  <span className="text-gray-600 text-xs">Type</span>{" "}
                  <span className="text-gray-800 text-md font-semibold">
                    <Badge
                      value={INSTITUTIONS_TYPES[institution.type]}
                      severity={INSTITUTIONS_TYPES_COLORS[institution.type]}
                    />
                  </span>
                </div>
                <div className="flex flex-col gap-1 col-span-1">
                  <span className="text-gray-600 text-xs">Country</span>{" "}
                  <span className="text-gray-800 text-md font-semibold">
                    {institution.country.name}
                  </span>
                </div>
                <div className="flex flex-col gap-1 col-span-1">
                  <span className="text-gray-600  text-xs">City</span>{" "}
                  <span className="text-gray-800 text-md font-semibold">
                    {institution.region.name}
                  </span>
                </div>
                <div className="flex flex-col gap-1 col-span-1">
                  <span className="text-gray-600 text-xs">Area</span>{" "}
                  <span className="text-gray-800 text-md font-semibold">
                    {institution.locality.name}
                  </span>
                </div>
              </div>
            </div>

            <div className="col-span-3 mt-3">
              <TabView>
                {!inRescueProgram && (
                  <TabPanel header="Schedule">
                    <div className="flex justify-end">
                      <Button
                        className="p-button-text p-button-sm my-1"
                        label="Edit Institution Schedule"
                        icon="pi pi-pencil"
                        size="small"
                        onClick={() => setIsIsntitutionScheduleVisible(true)}
                      />
                    </div>
                    <DataTable
                      value={institution.availableHours}
                      className="w-full text-center text-sm"
                      emptyMessage="No times found."
                      size="small"
                    >
                      <Column
                        field="day"
                        header="Day"
                        body={dayColumnTemplate}
                      />
                      <Column
                        field="from"
                        header="From"
                        body={fromColumnTemplate}
                      />
                      <Column field="to" header="To" body={toColumnTemplate} />
                    </DataTable>
                  </TabPanel>
                )}

                {["CLINIC", "HOSPITAL"].includes(institution.type) &&
                !inRescueProgram ? (
                  <TabPanel header="Doctors">
                    <div className="col-span-3">
                      <div className="flex items-center justify-end mb-2">
                        <Button
                          className="p-button-sm p-button-text"
                          label="Add Doctor"
                          icon="pi pi-plus"
                          onClick={() => setIsDoctorAddVisible(true)}
                        />
                      </div>
                      <hr className="mb-5" />

                      <DataTable
                        value={doctors}
                        emptyMessage="No doctors found."
                        size={"small"}
                      >
                        <Column
                          field="name"
                          header="Name"
                          className="text-sm py-0 lg:min-w-12rem"
                        />
                        <Column
                          header="Actions"
                          body={doctorsActionsBodyTemplate}
                        />
                      </DataTable>
                    </div>
                  </TabPanel>
                ) : null}

                {!inRescueProgram && (
                  <TabPanel header="Services">
                    <div className="flex items-center justify-end mb-2">
                      <Button
                        className="p-button-sm p-button-text"
                        label="Add Service"
                        icon="pi pi-plus"
                        onClick={() => setIsPetServiceAddVisible(true)}
                      />
                    </div>
                    <DataTable
                      value={services}
                      className="w-full text-center text-sm"
                      emptyMessage="No services found."
                      size="small"
                    >
                      <Column field="title" header="Title" />
                      <Column
                        field="type"
                        header="Type"
                        body={serviceTypeBodyTemplate}
                      />
                      <Column field="description" header="Description" />
                      <Column
                        field="offer.price"
                        header="Price"
                        body={servicePriceBodyTemplate}
                      />
                      <Column
                        header="Actions"
                        body={servicesActionsBodyTemplate}
                      />
                    </DataTable>
                  </TabPanel>
                )}

                {!inRescueProgram && (
                  <TabPanel header="Bookings">
                    <div className="flex items-center justify-end mb-2"></div>
                    <DataTable
                      value={bookings}
                      className="w-full text-center text-sm"
                      paginator
                      rows={10}
                      size="small"
                      dataKey="_id"
                      filters={filters}
                      onRowClick={(row) => {
                        setViewBooking(row.data._id);
                        setIsViewBookingVisible(true);
                      }}
                      filterDisplay={"row"}
                      globalFilterFields={[
                        "institution",
                        "total",
                        "paymentStatus",
                        "status",
                      ]}
                      rowHover
                      emptyMessage="No bookings found."
                    >
                      <Column
                        header="Date"
                        body={(rowData) => {
                          return (
                            <span>
                              {moment(rowData.time, "X").format("DD-MM-YYYY")}
                            </span>
                          );
                        }}
                      />
                      <Column field="user.name" header="User" />
                      <Column
                        field="total"
                        header="Price"
                        body={(rowData) => {
                          return (
                            <span>
                              {new Intl.NumberFormat("en-EG", {
                                style: "currency",
                                currency: "EGP",
                              }).format(rowData.total)}
                            </span>
                          );
                        }}
                      />
                      <Column
                        header="Status"
                        field="status"
                        filterField="status"
                        filter
                        showFilterMenu={false}
                        filterElement={statusesFilterTemplate}
                        body={(rowData) => {
                          return (
                            <Badge
                              severity={renderBookingStatusBackground(
                                rowData.paymentStatus
                              )}
                              value={renderBookingStatus(rowData.status)}
                            />
                          );
                        }}
                      />
                      <Column
                        header="Payment Status"
                        field="paymentStatus"
                        filterField="paymentStatus"
                        filter
                        showFilterMenu={false}
                        filterElement={paymentStatusesFilterTemplate}
                        body={(rowData) => {
                          return (
                            <Badge
                              severity={renderBookingPaymentStatusBackground(
                                rowData.paymentStatus
                              )}
                              value={renderBookingPaymentStatus(
                                rowData.paymentStatus
                              )}
                            />
                          );
                        }}
                      />
                      <Column
                        header="Actions"
                        body={bookingsActionsBodyTemplate}
                      />
                    </DataTable>
                  </TabPanel>
                )}

                {institution.inRescueProgram && (
                  <TabPanel header="Animals">
                    <div className="flex items-center justify-end mb-2">
                      <Button
                        className="p-button-sm p-button-text"
                        label="Add Animal"
                        icon="pi pi-plus"
                        onClick={() => setIsAddAnimalVisible(true)}
                      />
                    </div>
                    <DataTable
                      value={rescueAnimals}
                      className="w-full text-center text-sm"
                      emptyMessage="No animals found."
                      size="small"
                    >
                      <Column field="name" header="Name" />
                    </DataTable>
                  </TabPanel>
                )}
              </TabView>
            </div>
          </div>
        </>
      ) : null}

      {institution && (
        <>
          <Dialog
            header="Add Doctor"
            visible={isDoctorAddVisible}
            style={{ width: "50vw" }}
            onHide={() => setIsDoctorAddVisible(false)}
          >
            <AddDoctor
              institutionId={institution._id}
              representorId={user._id}
              setModalVisible={setIsDoctorAddVisible}
              setDoctors={setDoctors}
              ownResource={true}
            />
          </Dialog>
          <Dialog
            header="Add Service"
            visible={isPetServiceAddVisible}
            style={{ width: "50vw" }}
            onHide={() => setIsPetServiceAddVisible(false)}
          >
            <AddInstitutionPetService
              institutionId={institution._id}
              setModalVisible={setIsPetServiceAddVisible}
              setServices={setServices}
              representorId={user._id}
              institutionType={institution.type}
              ownResource={true}
              inMobileProgram={institution.inMobileProgram}
            />
          </Dialog>
          <Dialog
            header="Edit Doctor Schedule"
            visible={isInstitutionScheduleVisible}
            onHide={() => setIsIsntitutionScheduleVisible(false)}
            style={{ width: "50vw" }}
            breakpoints={{ "960px": "75vw", "641px": "100vw" }}
            closeOnEscape
            dismissableMask
          >
            <EditInstitutionSchedule
              setVisible={setIsIsntitutionScheduleVisible}
              institution={institution}
              setInstitution={setInstitution}
              ownResource={true}
            />
          </Dialog>
          <Dialog
            header="Add an animal"
            visible={isAddAnimalVisible}
            style={{ width: "50vw" }}
            onHide={() => setIsAddAnimalVisible(false)}
          >
            <AddRescueAnimal
              institutionId={institution._id}
              representorId={user._id}
              setModalVisible={setIsAddAnimalVisible}
              setRescueAnimals={setRescueAnimals}
              ownResource={true}
            />
          </Dialog>
        </>
      )}

      <Sidebar
        visible={isViewBookingVisible}
        position="right"
        style={{
          minWidth: 500,
        }}
        onHide={() => setIsViewBookingVisible(false)}
      >
        <ViewBookingDetails bookingId={viewBooking} />
      </Sidebar>
    </div>
  );
}

export default ViewOwnInstitution;
