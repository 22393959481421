export const renderBookingStatus = (status) => {
  const statuses = {
    CANCELLED: "Canceled",
    BOOKED: "Booked",
    COMPLETED: "Completed",
  };

  return statuses[status];
};

export const renderBookingPaymentStatus = (status) => {
  const paymentStatuses = {
    NOT_PAID: "Not Paid",
    PAID: "Paid",
  };

  return paymentStatuses[status];
};

export const renderBookingStatusBackground = (status) => {
  const statuses = {
    CANCELLED: "warning",
    BOOKED: "info",
    COMPLETED: "success",
  };

  return statuses[status];
};

export const renderBookingPaymentStatusBackground = (status) => {
  const paymentStatuses = {
    NOT_PAID: "warning",
    PAID: "success",
  };

  return paymentStatuses[status];
};

export const bookingStatuses = [
  {
    label: "Booked",
    value: "BOOKED",
  },
  {
    label: "Completed",
    value: "COMPLETED",
  },
  {
    label: "Cancelled",
    value: "CANCELLED",
  },
];

export const bookingPaymentStatuses = [
  {
    label: "Paid",
    value: "PAID",
  },
  {
    label: "Not Paid",
    value: "NOT_PAID",
  },
];
