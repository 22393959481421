import { Accordion, AccordionTab } from "primereact/accordion";
import React from "react";
import ChangePassword from "./components/ChangePassword";
import ChangeAvatar from "./components/ChangeAvatar";

function UserSettings() {
  return (
    <div>
      <h1 className="text-2xl font-bold text-slate-900">User Settings</h1>
      <Accordion className="mt-10" activeIndex={0}>
        <AccordionTab header="Avatar">
          <ChangeAvatar />
        </AccordionTab>
      </Accordion>
      <Accordion className="mt-10" activeIndex={0}>
        <AccordionTab header="Change Password">
          <ChangePassword />
        </AccordionTab>
      </Accordion>
    </div>
  );
}

export default UserSettings;
