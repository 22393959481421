import React from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Formik, Form } from "formik";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { handleResponseError } from "../../../utils/handleResponseError";

function ChangePassword() {
  const handleSubmit = (values, setSubmitting) => {
    setSubmitting(true);

    axios
      .patch("/user/private/password", values)
      .then((res) => {
        toast("Password changed successfully", {
          type: "success",
        });
      })
      .catch((e) => {
        handleResponseError(e);
      })
      .finally(() => setSubmitting(false));
  };

  const handleValidate = (values) => {
    const errors = {};

    if (!values.password) {
      errors.password = "Password is required";
    } else if (values.password.length < 6) {
      errors.password = "Password must be at least 6 characters long";
    } else if (values.newPassword !== values.passwordConfirmation) {
      errors.passwordConfirmation = "Passwords do not match";
    } else if (!values.passwordConfirmation) {
      errors.passwordConfirmation = "Password confirmation is required";
    } else if (values.passwordConfirmation.length < 6) {
      errors.passwordConfirmation =
        "Password must be at least 6 characters long";
    } else if (!values.newPassword) {
      errors.newPassword = "New password is required";
    } else if (values.newPassword.length < 6) {
      errors.newPassword = "Password must be at least 6 characters long";
    }

    return errors;
  };

  return (
    <Formik
      initialValues={{
        password: "",
        newPassword: "",
        passwordConfirmation: "",
      }}
      validate={(values) => handleValidate(values)}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmit(values, setSubmitting);
      }}
    >
      {({ isSubmitting, values, handleChange, errors, touched }) => (
        <Form>
          <div className="flex flex-col gap-7 mt-5">
            <div className="grid grid-cols-2 gap-4">
              <div className="col-span-2 md:col-span-1">
                <span className="p-float-label w-full">
                  <InputText
                    type="password"
                    id="password"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    className="w-full"
                  />
                  <label htmlFor="password">Old Password</label>
                </span>
                {errors.password && touched.password && (
                  <div id="password-error" className="p-error">
                    {errors.password}
                  </div>
                )}
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="col-span-2 md:col-span-1">
                <span className="p-float-label w-full">
                  <InputText
                    type="password"
                    id="newPassword"
                    name="newPassword"
                    value={values.newPassword}
                    onChange={handleChange}
                    className="w-full"
                  />
                  <label htmlFor="password">New Password</label>
                </span>
                {errors.newPassword && touched.newPassword && (
                  <div id="password-error" className="p-error">
                    {errors.newPassword}
                  </div>
                )}
              </div>
              <div className="col-span-2 md:col-span-1">
                <span className="p-float-label w-full">
                  <InputText
                    type="password"
                    id="passwordConfirmation"
                    name="passwordConfirmation"
                    value={values.passwordConfirmation}
                    onChange={handleChange}
                    className="w-full"
                  />
                  <label htmlFor="password">Confirm Password</label>
                </span>
                {errors.passwordConfirmation &&
                  touched.passwordConfirmation && (
                    <div id="password-error" className="p-error">
                      {errors.passwordConfirmation}
                    </div>
                  )}
              </div>
            </div>
            <div className="flex justify-end">
              <Button
                disabled={isSubmitting}
                loading={isSubmitting}
                label="Submit"
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default ChangePassword;
