import axios from "axios";
import { Avatar } from "primereact/avatar";
import { Button } from "primereact/button";
import { Rating } from "primereact/rating";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  INSTITUTIONS_TYPES,
  INSTITUTIONS_TYPES_COLORS,
  petServicesOptions,
} from "../../types/institution.types";
import { Badge } from "primereact/badge";
import { Dialog } from "primereact/dialog";
import AddDoctor from "../doctors/AddDoctor";
import { TabPanel, TabView } from "primereact/tabview";
import AddInstitutionPetService from "../pet-services/AddInstitutionPetService";
import { handleResponseError } from "../../utils/handleResponseError";
import { toast } from "react-toastify";
import EditInstitutionSchedule from "./components/EditInstitutionSchedule";

function ViewInstitution() {
  const navigate = useNavigate();
  const params = useParams();

  const [institution, setInstitution] = useState(null);
  const [doctors, setDoctors] = useState([]);
  const [services, setServices] = useState([]);

  const [isDoctorAddVisible, setIsDoctorAddVisible] = useState(false);
  const [isPetServiceAddVisible, setIsPetServiceAddVisible] = useState(false);
  const [isInstitutionScheduleVisible, setIsIsntitutionScheduleVisible] =
    useState(false);

  const doctorsActionsBodyTemplate = (rowData) => {
    return (
      <div className="flex align-items-center gap-2">
        <Button
          onClick={() => {
            navigate(`/doctor/${rowData._id}`);
          }}
          severity="primary"
          size={"small"}
          label="View"
          icon="pi pi-eye"
          className="p-button-text"
        />
        <Button
          className="p-button-text"
          severity="danger"
          size={"small"}
          label="Delete"
          onClick={() => handleDoctorDlete(rowData._id)}
          icon="pi pi-trash"
        />
      </div>
    );
  };

  const servicePriceBodyTemplate = (rowData) => {
    return (
      <div className="flex gap-2">
        {rowData.offers[0]?.price + " " + rowData.offers[0]?.priceCurrency}
      </div>
    );
  };

  const dayColumnTemplate = (rowData) => {
    return (
      <span>{rowData.day.charAt(0).toUpperCase() + rowData.day.slice(1)}</span>
    );
  };

  const fromColumnTemplate = (rowData) => {
    return <span>{rowData.from ? rowData.from : "N/A"}</span>;
  };

  const toColumnTemplate = (rowData) => {
    return <span>{rowData.to ? rowData.to : "N/A"}</span>;
  };

  useEffect(() => {
    axios
      .get(`/institution/${params._id}`)
      .then((res) => {
        res.data && setInstitution(res.data);
      })
      .catch((e) => {
        handleResponseError(e);
      });
  }, [params._id]);

  useEffect(() => {
    if (institution) {
      axios
        .get(`/service/list`, {
          params: {
            institution: institution._id,
          },
        })
        .then((res) => {
          res.data &&
            setServices(
              res.data.filter((service) => service.isDeleted === false)
            );
        })
        .catch((e) => {});
    }
  }, [institution]);

  useEffect(() => {
    if (institution && ["CLINIC", "HOSPITAL"].includes(institution.type)) {
      axios
        .get("/physician/public/list", {
          params: {
            institution: [params._id],
          },
        })
        .then((res) => {
          res.data && setDoctors(res.data);
        })
        .catch((e) => {});
    }
  }, [institution, params._id]);

  useEffect(() => {
    if (institution && institution.inRescueProgram) {
      axios
        .get("/animal/list", {
          params: {
            institution: [params._id],
          },
        })
        .then((res) => {
          res.data && setDoctors(res.data);
        })
        .catch((e) => {});
    }
  }, [institution, params._id]);

  const handleRestore = () => {};

  const handleDelete = () => {
    axios
      .delete(`/institution/${params._id}`)
      .then(() => {
        navigate("/institutions");
      })
      .catch((e) => {
        handleResponseError(e);
      });
  };

  const serviceTypeBodyTemplate = (rowData) => {
    const serviceTypes = [...petServicesOptions];
    const service = serviceTypes.find(
      (service) => service.value === rowData.type
    );
    return <span>{service.label}</span>;
  };

  const servicesActionsBodyTemplate = (rowData) => {
    return (
      <div className="flex align-items-center gap-2">
        <Button
          className="p-button-text"
          severity="danger"
          size={"small"}
          label="Delete"
          icon="pi pi-trash"
          onClick={() => handleServiceDelete(rowData._id)}
        />
      </div>
    );
  };

  const handleServiceDelete = (serviceId) => {
    axios
      .delete(`/service/${serviceId}`)
      .then((res) => {
        setServices(services.filter((service) => service._id !== serviceId));
        toast("Service deleted successfully.", {
          type: "success",
        });
      })
      .catch((e) => handleResponseError(e));
  };

  const handleDoctorDlete = (doctorId) => {
    axios
      .delete(`/physician/${doctorId}`)
      .then((res) => {
        setDoctors(doctors.filter((doctor) => doctor._id !== doctorId));
        toast("Doctor deleted successfully.", {
          type: "success",
        });
      })
      .catch((e) => handleResponseError(e));
  };

  const inResuceProgram = institution ? institution.inRescueProgram : false;

  const inMobileProgram = institution ? institution.inMobileProgram : false;

  return (
    <div>
      {institution ? (
        <>
          <div className="flex flex-col md:flex-row justify-center md:justify-between w-full">
            <div className="flex justify-center md:justify-start items-center gap-2">
              <Avatar
                image={institution.avatar ? institution.avatar : null}
                label={institution.name.charAt(0)}
                size="xlarge"
                className="border-2 border-gray-50 min-w-[4rem] min-h-[4rem] md:min-w-[10rem] md:min-h-[10rem] rounded-md"
              />
              <div className="flex flex-col gap-2">
                <h1 className="text-2xl font-semibold text-gray-800">
                  {institution.name}
                </h1>
                <Rating
                  value={institution.rating ? institution.rating : 0}
                  readOnly
                  stars={5}
                  cancel={false}
                />
              </div>
            </div>
            <div className="flex flex-col md:flex-row mt-6 md:mt-0 items-center gap-2">
              <Button
                onClick={() => {
                  navigate(`/institutions/edit/${institution._id}/`);
                }}
                className="p-button-text"
                label="Edit Information"
                icon="pi pi-pencil"
              />
              {institution.isDeleted ? (
                <>
                  <Button
                    onClick={handleRestore}
                    className="p-button-text"
                    label="Restore"
                    severity="success"
                    icon="pi pi-refresh"
                    disabled
                  />
                </>
              ) : (
                <>
                  <Button
                    onClick={handleDelete}
                    className="p-button-text"
                    label="Delete Institution"
                    severity="danger"
                    icon="pi pi-trash"
                  />
                </>
              )}
            </div>
          </div>
          <div className="grid grid-cols-3 gap-10 mt-10">
            <div className="col-span-3 md:col-span-2  bg-gray-50 p-5 rounded-md">
              <div className="flex items-center justify-between">
                <span className="text-gray-800 font-bold">
                  General Information
                </span>
              </div>
              <hr className="mb-5" />
              <div className="grid grid-cols-2 gap-3">
                <div className="flex flex-col gap-1 col-span-2">
                  <span className="text-gray-600 text-xs">Address</span>{" "}
                  <span className="text-gray-800 text-md font-semibold">
                    {institution.line1}
                  </span>
                </div>
                <div className="flex flex-col gap-1 col-span-1">
                  <span className="text-gray-600 text-xs">Type</span>{" "}
                  <span className="text-gray-800 text-md font-semibold">
                    <Badge
                      value={INSTITUTIONS_TYPES[institution.type]}
                      severity={INSTITUTIONS_TYPES_COLORS[institution.type]}
                    />
                  </span>
                </div>
                <div className="flex flex-col gap-1 col-span-1">
                  <span className="text-gray-600 text-xs">Country</span>{" "}
                  <span className="text-gray-800 text-md font-semibold">
                    {institution.country.name}
                  </span>
                </div>
                <div className="flex flex-col gap-1 col-span-1">
                  <span className="text-gray-600  text-xs">City</span>{" "}
                  <span className="text-gray-800 text-md font-semibold">
                    {institution.region.name}
                  </span>
                </div>
                <div className="flex flex-col gap-1 col-span-1">
                  <span className="text-gray-600 text-xs">Area</span>{" "}
                  <span className="text-gray-800 text-md font-semibold">
                    {institution.locality.name}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-span-3 md:col-span-1  bg-gray-50 p-5 rounded-md">
              <div className="flex items-center justify-between">
                <span className="text-gray-800 font-bold">
                  Representor Information
                </span>
              </div>
              <hr className="mb-5" />
              <div className="grid grid-cols-2 gap-3">
                <div
                  onClick={() => {
                    navigate(`/users/${institution.representor._id}`);
                  }}
                  className="flex w-full items-center gap-4 col-span-2 p-1 rounded-md hover:bg-gray-100 hover:cursor-pointer"
                >
                  <Avatar
                    image={
                      institution.representor.avatar
                        ? institution.representor.avatar
                        : null
                    }
                    label={institution.representor?.name?.charAt(0)}
                    size="large"
                    shape="circle"
                  />
                  <span className="text-gray-800 text-md font-semibold">
                    {institution.representor.name}
                  </span>
                </div>
              </div>
            </div>

            <div className="col-span-3 mt-3">
              <TabView>
                {!inResuceProgram && (
                  <TabPanel header="Schedule">
                    <div className="flex justify-end">
                      <Button
                        className="p-button-text p-button-sm my-1"
                        label="Edit Institution Schedule"
                        icon="pi pi-pencil"
                        size="small"
                        onClick={() => setIsIsntitutionScheduleVisible(true)}
                      />
                    </div>
                    <DataTable
                      value={institution.availableHours}
                      className="w-full text-center text-sm"
                      emptyMessage="No times found."
                      size="small"
                    >
                      <Column
                        field="day"
                        header="Day"
                        body={dayColumnTemplate}
                      />
                      <Column
                        field="from"
                        header="From"
                        body={fromColumnTemplate}
                      />
                      <Column field="to" header="To" body={toColumnTemplate} />
                    </DataTable>
                  </TabPanel>
                )}

                {["CLINIC", "HOSPITAL"].includes(institution.type) &&
                !inResuceProgram ? (
                  <TabPanel header="Doctors">
                    <div className="col-span-3">
                      <div className="flex items-center justify-end mb-2">
                        <Button
                          className="p-button-sm p-button-text"
                          label="Add Doctor"
                          icon="pi pi-plus"
                          onClick={() => setIsDoctorAddVisible(true)}
                        />
                      </div>
                      <hr className="mb-5" />

                      <DataTable
                        value={doctors}
                        emptyMessage="No doctors found."
                        size={"small"}
                      >
                        <Column
                          field="name"
                          header="Name"
                          className="text-sm py-0 lg:min-w-12rem"
                        />
                        <Column
                          header="Actions"
                          body={doctorsActionsBodyTemplate}
                        />
                      </DataTable>
                    </div>
                  </TabPanel>
                ) : null}

                {!inResuceProgram && (
                  <TabPanel header="Services">
                    <div className="flex items-center justify-end mb-2">
                      <Button
                        className="p-button-sm p-button-text"
                        label="Add Service"
                        icon="pi pi-plus"
                        onClick={() => setIsPetServiceAddVisible(true)}
                      />
                    </div>
                    <DataTable
                      value={services}
                      className="w-full text-center text-sm"
                      emptyMessage="No services found."
                      size="small"
                    >
                      <Column field="title" header="Title" />
                      <Column
                        field="type"
                        header="Type"
                        body={serviceTypeBodyTemplate}
                      />
                      <Column field="description" header="Description" />
                      <Column
                        field="offer.price"
                        header="Price"
                        body={servicePriceBodyTemplate}
                      />
                      <Column
                        header="Actions"
                        body={servicesActionsBodyTemplate}
                      />
                    </DataTable>
                  </TabPanel>
                )}

                {institution.inRescueProgram ? (
                  <TabPanel header="Animals">
                    <div className="col-span-3">
                      <div className="flex items-center justify-end mb-2">
                        <Button
                          className="p-button-sm p-button-text"
                          label="Add Doctor"
                          icon="pi pi-plus"
                          onClick={() => setIsDoctorAddVisible(true)}
                        />
                      </div>
                      <hr className="mb-5" />

                      <DataTable
                        value={doctors}
                        emptyMessage="No doctors found."
                        size={"small"}
                      >
                        <Column
                          field="name"
                          header="Name"
                          className="text-sm py-0 lg:min-w-12rem"
                        />
                        <Column
                          header="Actions"
                          body={doctorsActionsBodyTemplate}
                        />
                      </DataTable>
                    </div>
                  </TabPanel>
                ) : null}
              </TabView>
            </div>
          </div>
        </>
      ) : null}
      {institution && (
        <>
          <Dialog
            header="Add Doctor"
            visible={isDoctorAddVisible}
            style={{ width: "50vw" }}
            onHide={() => setIsDoctorAddVisible(false)}
          >
            <AddDoctor
              institutionId={institution._id}
              representorId={institution.representor._id}
              setModalVisible={setIsDoctorAddVisible}
              setDoctors={setDoctors}
            />
          </Dialog>
          <Dialog
            header="Add Service"
            visible={isPetServiceAddVisible}
            style={{ width: "50vw" }}
            onHide={() => setIsPetServiceAddVisible(false)}
          >
            <AddInstitutionPetService
              institutionId={institution._id}
              setModalVisible={setIsPetServiceAddVisible}
              setServices={setServices}
              representorId={institution.representor._id}
              institutionType={institution.type}
              inMobileProgram={institution.inMobileProgram}
            />
          </Dialog>
          <Dialog
            header="Edit Doctor Schedule"
            visible={isInstitutionScheduleVisible}
            onHide={() => setIsIsntitutionScheduleVisible(false)}
            style={{ width: "50vw" }}
            breakpoints={{ "960px": "75vw", "641px": "100vw" }}
            closeOnEscape
            dismissableMask
          >
            <EditInstitutionSchedule
              setVisible={setIsIsntitutionScheduleVisible}
              institution={institution}
              setInstitution={setInstitution}
              ownResource={false}
            />
          </Dialog>
        </>
      )}
    </div>
  );
}

export default ViewInstitution;
