import React, { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useFormik } from "formik";
import { handleResponseError } from "../../utils/handleResponseError";

function AddInstitution() {
  const params = useParams();
  const navigate = useNavigate();

  const [countries, setCountries] = useState([]);
  const [regions, setRegions] = useState([]);
  const [localities, setLocalities] = useState([]);

  const addInstitutionForm = useFormik({
    initialValues: {
      name: "",
      country: "",
      region: "",
      locality: "",
      representor: params.userId,
      type: "",
      line1: "",
      availableHours: [{ day: "Sun", from: "07:00", to: "20:00" }],
    },
    validate: (data) => {
      let errors = {};

      let requiredFields = [
        {
          name: "name",
          label: "Name",
        },
        {
          name: "country",
          label: "Country",
        },
        {
          name: "region",
          label: "Region",
        },
        {
          name: "locality",
          label: "Locality",
        },
        {
          name: "type",
          label: "Type",
        },
        {
          name: "line1",
          label: "Address",
        },
      ];

      requiredFields.forEach((field) => {
        if (!data[field.name]) {
          errors[field.name] = `${field.label} is required`;
        }
      });

      return errors;
    },
    onSubmit: (data, { setSubmitting }) => {
      if (data) {
        axios
          .post("/institution", data)
          .then((res) => {
            navigate(`/institutions/${res.data._id}`);
          })
          .catch((e) => handleResponseError(e))
          .finally(() => setSubmitting(false));
      }
    },
  });

  useEffect(() => {
    axios
      .get("/region/list?type=COUNTRY")
      .then((res) => setCountries(res.data));
  }, []);

  useEffect(() => {
    if (addInstitutionForm.values.country) {
      axios
        .get(
          `/region/list?type=REGION&parents=${addInstitutionForm.values.country}`
        )
        .then((res) =>
          setRegions(
            res.data.sort((a, b) => {
              const nameA = a.name.toLowerCase();
              const nameB = b.name.toLowerCase();

              if (nameA < nameB) return -1;
              if (nameA > nameB) return 1;
              return 0;
            })
          )
        );
    }
  }, [addInstitutionForm.values.country]);

  useEffect(() => {
    if (addInstitutionForm.values.region) {
      axios
        .get(
          `/region/list?type=LOCALITY&parents=${addInstitutionForm.values.region}`
        )
        .then((res) =>
          setLocalities(
            res.data.sort((a, b) => {
              const nameA = a.name.toLowerCase();
              const nameB = b.name.toLowerCase();

              if (nameA < nameB) return -1;
              if (nameA > nameB) return 1;
              return 0;
            })
          )
        );
    }
  }, [addInstitutionForm.values.region]);

  const isFormFieldInvalid = (name) =>
    !!(addInstitutionForm.touched[name] && addInstitutionForm.errors[name]);

  const getFormErrorMessage = (name) => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{addInstitutionForm.errors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const institutionTypes = [
    {
      label: "Hospital",
      value: "HOSPITAL",
    },
    {
      label: "Clinic",
      value: "CLINIC",
    },
    {
      label: "Grooming Center",
      value: "CENTER",
    },
  ];

  return (
    <div>
      <h1 className="text-3xl font-bold">Add Institution</h1>
      <p className="text-gray-400">Add a new institution</p>
      <hr className="my-5" />
      <span className="font-bold my-10">Institution Information</span>
      <hr className="pb-10" />
      <div className="grid grid-cols-3 gap-5">
        <form onSubmit={addInstitutionForm.handleSubmit} className="col-span-2">
          <div className="flex flex-col gap-4 w-full">
            <div className="flex flex-col gap-2 w-full">
              <div className="grid grid-cols-2 gap-3">
                <div className="col-span-2">
                  <span className="p-float-label w-full">
                    <InputText
                      id="name"
                      name="name"
                      value={addInstitutionForm.values.name}
                      onChange={(e) => {
                        addInstitutionForm.setFieldValue(
                          "name",
                          e.target.value
                        );
                      }}
                      className={classNames({
                        "p-invalid": isFormFieldInvalid("name"),
                        "w-full": true,
                      })}
                    />
                    <label htmlFor="name_en">Name</label>
                  </span>
                  {getFormErrorMessage("name")}
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <span className="p-float-label w-full">
                <Dropdown
                  id="type"
                  name="type"
                  options={institutionTypes}
                  value={addInstitutionForm.values.type}
                  onChange={(e) => {
                    addInstitutionForm.setFieldValue("type", e.target.value);
                  }}
                  className={classNames({
                    "p-invalid": isFormFieldInvalid("type"),
                    "w-full": true,
                  })}
                />
                <label htmlFor="type">Type</label>
              </span>
              {getFormErrorMessage("type")}
            </div>

            <div className="flex flex-col gap-2">
              <div className="grid grid-cols-2 gap-2">
                <div className="col-span-1">
                  <span className="p-float-label w-full">
                    <Dropdown
                      options={countries}
                      id="country"
                      name="country"
                      optionLabel="name"
                      optionValue="_id"
                      value={addInstitutionForm.values.country}
                      onChange={(e) => {
                        addInstitutionForm.setFieldValue("country", e.value);
                      }}
                      className={classNames({
                        "p-invalid": isFormFieldInvalid("country"),
                        "w-full": true,
                      })}
                    />
                    <label htmlFor="country">Country</label>
                  </span>
                  {getFormErrorMessage("country")}
                </div>
                <div className="col-span-1">
                  <span className="p-float-label w-full">
                    <Dropdown
                      options={regions}
                      id="region"
                      name="region"
                      value={addInstitutionForm.values.region}
                      optionLabel="name"
                      optionValue="_id"
                      onChange={(e) => {
                        addInstitutionForm.setFieldValue("region", e.value);
                      }}
                      filter
                      className={classNames({
                        "p-invalid": isFormFieldInvalid("region"),
                        "w-full": true,
                      })}
                    />
                    <label htmlFor="region">Region</label>
                  </span>
                  {getFormErrorMessage("region")}
                </div>
                <div className="col-span-1">
                  <span className="p-float-label w-full">
                    <Dropdown
                      options={localities}
                      id="locality"
                      name="locality"
                      value={addInstitutionForm.values.locality}
                      optionLabel="name"
                      optionValue="_id"
                      onChange={(e) => {
                        addInstitutionForm.setFieldValue("locality", e.value);
                      }}
                      filter
                      className={classNames({
                        "p-invalid": isFormFieldInvalid("locality"),
                        "w-full": true,
                      })}
                    />
                    <label htmlFor="locality">Locality</label>
                  </span>
                  {getFormErrorMessage("locality")}
                </div>
                <div className="col-span-2">
                  <span className="p-float-label w-full">
                    <InputText
                      id="line1"
                      name="line1"
                      value={addInstitutionForm.values.line1}
                      onChange={(e) => {
                        addInstitutionForm.setFieldValue(
                          "line1",
                          e.target.value
                        );
                      }}
                      className={classNames({
                        "p-invalid": isFormFieldInvalid("line1"),
                        "w-full": true,
                      })}
                    />
                    <label htmlFor="line1">Address</label>
                  </span>
                  {getFormErrorMessage("line1")}
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-2">
              <Button
                loading={addInstitutionForm.isSubmitting}
                disabled={
                  addInstitutionForm.isSubmitting ||
                  !addInstitutionForm.dirty ||
                  !addInstitutionForm.isValid
                }
                type="submit"
                label="Create Institution"
              />
            </div>
          </div>
        </form>
        <div className="col-span-1"></div>
      </div>
    </div>
  );
}

export default AddInstitution;
