import React from "react";
import Navbar from "../components/navbar/Navbar";
import { Outlet } from "react-router-dom";
import { Card } from "primereact/card";
import MobileHeader from "../components/mobile-header/MobileHeader";

function DashboardLayout() {
  return (
    <div className="flex flex-col md:flex-row h-screen bg-gray-100">
      <MobileHeader />
      <Navbar />
      <div className="flex flex-col overflow-scroll items-center flex-grow">
        <Card className="px-4 rounded-sm m-0 container min-h-full overflow-scroll">
          <Outlet />
        </Card>
      </div>
    </div>
  );
}

export default DashboardLayout;
