import axios from "axios";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import OTPInput from "react-otp-input";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { motion } from "framer-motion";
import { handleResponseError } from "../../utils/handleResponseError";

function ForgetPassword() {
  const navigate = useNavigate();

  const [registerStage, setRegisterStage] = useState("first");
  const [otp, setOtp] = useState("");
  const [tempToken, setTempToken] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (otp.length === 6) {
      handleSecondStep();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otp]);

  const handleFirstStep = (values) => {
    axios
      .post("/identification/restoration/email", { email: values.email })
      .then((res) => {
        setTempToken(res.data.token);
        setRegisterStage("second");
      })
      .catch((e) => handleResponseError(e));
  };

  const handleSecondStep = () => {
    axios
      .post(
        "/identification/restoration/email/verify",
        {
          otp,
        },
        {
          headers: {
            Authorization: `Bearer ${tempToken}`,
          },
        }
      )
      .then((res) => {
        setTempToken(res.data.token);
        setRegisterStage("third");
      })
      .catch((e) => handleResponseError(e));
  };

  const handleThirdStep = (values) => {
    axios
      .post(
        "/identification/restoration/complete",
        {
          password: values.password,
          passwordConfirmation: values.passwordConfirmation,
        },
        {
          headers: {
            Authorization: `Bearer ${tempToken}`,
          },
        }
      )
      .then((res) => {
        localStorage.setItem("token", res.data.token);
        navigate("/");
      })
      .catch((e) => handleResponseError(e));
  };

  return (
    <div className="bg-indigo-400 flex justify-center h-screen w-full items-center">
      <div className="bg-white p-7 shadow-2 rounded lg:w-[500px]">
        <div className="text-center mb-5">
          <h1 className="text-3xl font-bold">Reset Password</h1>
        </div>

        <div>
          <>
            <Formik
              initialValues={{ email: "" }}
              validate={(values) => {
                const errors = {};
                if (!values.email) {
                  errors.email = "Email is required";
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = "Invalid email address";
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                setEmail(values.email);
                handleFirstStep(values);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="flex flex-col gap-2">
                    <div className="input w-full relative">
                      <InputText
                        type="email"
                        name="email"
                        className="peer py-3 px-4 ps-11 block w-full bg-gray-100 border-transparent rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                        placeholder="Enter email"
                        disabled={registerStage !== "first"}
                        onChange={handleChange}
                        value={values.email}
                      />

                      <div className="absolute inset-y-0 start-0 flex items-center pointer-events-none ps-4 peer-disabled:opacity-50 peer-disabled:pointer-events-none">
                        <svg
                          className="flex-shrink-0 w-4 h-4 text-gray-500"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path d="M19 21v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2" />
                          <circle cx="12" cy="7" r="4" />
                        </svg>
                      </div>
                    </div>

                    {errors.email && (
                      <span className="flex my-1  p-1 rounded px-5 w-full bg-red-200 text-red-500 text-xs">
                        {errors.email}
                      </span>
                    )}

                    {registerStage === "first" && (
                      <div className="input w-full relative">
                        <Button
                          type="submit"
                          className="peer py-3 block w-full border-transparent rounded-lg text-sm  disabled:opacity-50 disabled:pointer-events-none shadow-lg"
                        >
                          Proceed
                        </Button>
                      </div>
                    )}
                  </div>
                </form>
              )}
            </Formik>

            {registerStage === "second" && (
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="input flex flex-col gap-5 justify-center w-full border-2 mt-5 p-5 rounded-md"
              >
                <span className="text-center">
                  Enter the OTP sent to
                  <span className="font-bold">{" " + email}</span>
                </span>
                <OTPInput
                  inputStyle={{
                    minWidth: 50,
                    borderRadius: "0.2rem",
                    borderWidth: 2,
                  }}
                  containerStyle={{
                    gap: 5,
                  }}
                  value={otp}
                  onChange={setOtp}
                  numInputs={6}
                  inputType="number"
                  renderSeparator={<span>-</span>}
                  renderInput={(props) => <input {...props} />}
                />
              </motion.div>
            )}

            {registerStage === "third" && (
              <>
                <Formik
                  initialValues={{ password: "", passwordConfirmation: "" }}
                  validate={(values) => {
                    const errors = {};

                    if (!values.password) {
                      errors.email = "Email is required";
                    } else if (values.password.length < 6) {
                      errors.password =
                        "Password must be at least 6 characters";
                    }
                    if (!values.passwordConfirmation) {
                      errors.passwordConfirmation =
                        "Password confirmation is required";
                    }
                    if (values.password !== values.passwordConfirmation) {
                      errors.passwordConfirmation = "Passwords do not match";
                    }
                    return errors;
                  }}
                  onSubmit={(values) => {
                    handleThirdStep(values);
                  }}
                >
                  {({ values, errors, handleChange }) => (
                    <form
                      className="flex flex-col gap-5 mt-5"
                      onSubmit={handleThirdStep}
                    >
                      <div className="input w-full relative">
                        <input
                          type="password"
                          className="peer py-3 px-4 ps-11 block w-full bg-gray-100 border-transparent rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                          placeholder="New Password"
                          value={values.password}
                          name="password"
                          onChange={handleChange}
                        />
                        <div className="absolute inset-y-0 start-0 flex items-center pointer-events-none ps-4 peer-disabled:opacity-50 peer-disabled:pointer-events-none">
                          <svg
                            className="flex-shrink-0 w-4 h-4 text-gray-500"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path d="M2 18v3c0 .6.4 1 1 1h4v-3h3v-3h2l1.4-1.4a6.5 6.5 0 1 0-4-4Z" />
                            <circle cx="16.5" cy="7.5" r=".5" />
                          </svg>
                        </div>
                      </div>

                      {errors.password && (
                        <span className="flex my-1  p-1 rounded px-5 w-full bg-red-200 text-red-500 text-xs">
                          {errors.password}
                        </span>
                      )}
                      <div className="input w-full relative">
                        <input
                          type="password"
                          className="peer py-3 px-4 ps-11 block w-full bg-gray-100 border-transparent rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                          placeholder="Password Confirmation"
                          value={values.passwordConfirmation}
                          name="passwordConfirmation"
                          onChange={handleChange}
                        />
                        <div className="absolute inset-y-0 start-0 flex items-center pointer-events-none ps-4 peer-disabled:opacity-50 peer-disabled:pointer-events-none">
                          <svg
                            className="flex-shrink-0 w-4 h-4 text-gray-500"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path d="M2 18v3c0 .6.4 1 1 1h4v-3h3v-3h2l1.4-1.4a6.5 6.5 0 1 0-4-4Z" />
                            <circle cx="16.5" cy="7.5" r=".5" />
                          </svg>
                        </div>
                      </div>

                      {errors.passwordConfirmation && (
                        <span className="flex my-1  p-1 rounded px-5 w-full bg-red-200 text-red-500 text-xs">
                          {errors.passwordConfirmation}
                        </span>
                      )}

                      <div className="input w-full relative">
                        <Button
                          type="button"
                          onClick={() => {
                            handleThirdStep(values);
                          }}
                          className="peer py-3 block w-full border-transparent rounded-lg text-sm disabled:opacity-50 disabled:pointer-events-none shadow-lg"
                        >
                          Submit
                        </Button>
                      </div>
                    </form>
                  )}
                </Formik>
              </>
            )}
          </>
        </div>

        <div className="flex justify-center mt-5">
          <Button
            label="Back to login"
            onClick={() => navigate("/login")}
            className="text-sm p-button-text"
          />
        </div>
      </div>
    </div>
  );
}

export default ForgetPassword;
