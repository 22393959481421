import React, { useContext, useState } from "react";
import { UserContext } from "../../../contexts/UserContext";
import logo from "../../../assets/images/logo.jpeg";
import {
  PiCaretDownBold,
  PiCaretUpBold,
  PiHouseFill,
  PiTicketFill,
  PiUserFill,
} from "react-icons/pi";
import { FaHospitalSymbol, FaServicestack } from "react-icons/fa";
import { NavLink, useNavigate } from "react-router-dom";
import { Avatar } from "primereact/avatar";
import { InstitutionsContext } from "../../../contexts/InstitutionsContext";

const NavbarItem = ({ icon, label, to }) => {
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        isActive
          ? "menu-option flex gap-2 items-center font-[700] py-2 px-1 rounded bg-indigo-100 text-indigo-700 hover:bg-indigo-200 hover:cursor-pointer transition-all ease-in-out duration-200"
          : "menu-option flex gap-2 items-center font-[600] py-2 px-1 rounded text-gray-500 hover:bg-indigo-200 hover:text-indigo-700 hover:cursor-pointer transition-all ease-in-out  duration-200"
      }
    >
      {icon}
      <span className="text-md">{label}</span>
    </NavLink>
  );
};

function Navbar() {
  const navigate = useNavigate();

  const [userMenuOpened, setUserMenuOpened] = useState(false);

  const { user, setUser } = useContext(UserContext);
  const { institutions, setInstitutions } = useContext(InstitutionsContext);

  const toggleUserMenu = () => setUserMenuOpened(!userMenuOpened);

  const logout = () => {
    localStorage.removeItem("token");
    setUser(null);
    setInstitutions([]);
    navigate("/login");
  };

  const menuOptions = [
    {
      entries: [
        {
          icon: <PiHouseFill className="text-md" />,
          label: "Home",
          to: "/",
          allowedRoles: ["ADMIN", "REPRESENTOR"],
        },
        {
          icon: <FaHospitalSymbol className="text-md" />,
          label: "Institutions",
          to: "/institutions",
          allowedRoles: ["ADMIN"],
        },
        {
          icon: <PiUserFill className="text-md" />,
          label: "Users",
          to: "/users",
          allowedRoles: ["ADMIN"],
        },
        {
          icon: <PiTicketFill className="text-md" />,
          label: "Bookings",
          to: "/bookings",
          allowedRoles: ["ADMIN"],
        },
        {
          icon: <PiTicketFill className="text-md" />,
          label: "Manage Bookings",
          to: "/rep-bookings",
          allowedRoles: ["REPRESENTOR"],
        },
      ],
    },
  ];

  return (
    <div className="hidden md:flex p-4 space-y-4 overflow-hidden bg-gray-50 border-e-2 border-spacing-1 border-gray-100 shadow-md w-80 h-full  flex-col">
      <img src={logo} alt="Vetsee" className="w-20" />
      <hr className="border-slate-200" />
      <div className="flex flex-col flex-1 gap-4">
        <div className="flex flex-col gap-1">
          <span className="my-1">
            <small className="text-sm text-slate-500 font-semibold">
              Navigate
            </small>
          </span>
          {menuOptions.map((section, index) => (
            <React.Fragment key={index}>
              {section.entries.map((entry, index) => (
                <React.Fragment key={index}>
                  {entry.allowedRoles?.includes(user?.role) && (
                    <NavbarItem
                      key={entry.to}
                      icon={entry.icon}
                      label={entry.label}
                      to={entry.to}
                    />
                  )}
                </React.Fragment>
              ))}
              {index !== menuOptions.length - 1 && (
                <hr className="border-slate-200" />
              )}
            </React.Fragment>
          ))}
        </div>

        <hr />

        <div className="flex flex-col gap-1 flex-1">
          {institutions?.length > 0 && (
            <span className="my-1">
              <small className="text-sm text-slate-500">Institutions</small>
            </span>
          )}
          {institutions?.length > 0 &&
            institutions.map((institution, index) => (
              <NavLink
                to={`/institutions/private/${institution._id}`}
                className={({ isActive }) =>
                  isActive
                    ? "menu-option flex gap-2 items-center font-[700] py-2 px-1 rounded bg-indigo-100 text-indigo-700 hover:bg-indigo-200  hover:cursor-pointer transition-all ease-in-out duration-200"
                    : "menu-option flex gap-2 items-center font-[600] text-gray-500 py-2 px-1 rounded  hover:bg-indigo-200 hover:text-indigo-700 hover:cursor-pointer transition-all ease-in-out  duration-200"
                }
                key={index}
              >
                <FaServicestack />
                <span className="text-md">{institution.name}</span>
              </NavLink>
            ))}
        </div>
      </div>

      <div>
        <div
          onClick={toggleUserMenu}
          className={`flex flex-col gap-2 transition-all ease-in-out duration-100 rounded-lg ${
            userMenuOpened ? "shadow-md p-2" : ""
          }`}
        >
          <div
            className={`menu-option w-full flex gap-2 items-center font-[600] ${
              userMenuOpened ? "" : "hover:bg-gray-100"
            } p-2 rounded  hover:cursor-pointer`}
          >
            <div className="w-full flex gap-2 items-center">
              <Avatar
                image={user?.avatar}
                className="w-10"
                alt="user avatar"
                shape="circle"
                label={user?.name && user.name[0]}
              />
              <div className="flex flex-col">
                <span className="text-md  text-slate-600">
                  {user?.name && user.name}
                </span>
                <small className="text-xs text-slate-600">
                  {user?.email && user.email}
                </small>
              </div>
            </div>
            {userMenuOpened ? (
              <PiCaretUpBold className="text-md text-slate-600" />
            ) : (
              <PiCaretDownBold className="text-md text-slate-600" />
            )}
          </div>
          <div className={`${userMenuOpened ? "flex" : "hidden"}`}>
            <div
              className={`menu-option w-full overflow-hidden  gap-2 items-center font-[600] rounded  transition-all ease-in-out duration-100`}
            >
              <hr className="border-slate-200" />
              <div
                onClick={() => navigate("/user-settings")}
                className="w-full flex gap-2 p-2 items-center hover:bg-gray-100 hover:cursor-pointer"
              >
                <span className="text-md  text-slate-600">Settings</span>
              </div>
              <div
                onClick={logout}
                className="w-full flex gap-2 p-2 items-center hover:bg-gray-100 hover:cursor-pointer"
              >
                <span className="text-md  text-slate-600">Logout</span>
              </div>
            </div>
          </div>
        </div>
        <small className="text-xs text-slate-600">
          © 2024 Vetsee. All rights reserved.
        </small>
      </div>
    </div>
  );
}

export default Navbar;
