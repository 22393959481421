import { Button } from "primereact/button";
import React from "react";
import { useNavigate } from "react-router-dom";

function NotFound() {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className="h-[90vh] flex flex-col gap-4 justify-center items-center">
      <h1 className="text-3xl font-bold text-gray-600">Page Not Found</h1>
      <p className="text-gray-400">
        The page you are looking for does not exist
      </p>
      <Button
        onClick={handleGoBack}
        label="Go Back"
        className="p-button-text"
      />
    </div>
  );
}

export default NotFound;
