export const countries = {
  EG: "Egypt",
  SA: "Saudi Arabia",
};

export const userRoles = {
  ADMIN: "Admin",
  CLIENT: "Client",
  REPRESENTOR: "Representor",
};
