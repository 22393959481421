import React, { useState } from "react";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { doctorServices } from "../../../types/doctorServices";

function EditDoctorServices() {
  const [services, setServices] = useState([
    {
      uuid: 1,
      name: "Service 1",
      price: 100,
    },
  ]);

  const handleDeleteService = (uuid) => {
    const newServices = services.filter((service) => service.uuid !== uuid);
    setServices(newServices);
  };

  return (
    <div>
      {services.map((service) => (
        <div
          key={service.uuid}
          className="container w-full flex justify-between items-center"
        >
          <p>{service.name}</p>
          <p>{service.price}</p>
          <div className="flex gap-2">
            <Button
              className="p-button-text p-button-rounded"
              onClick={handleDeleteService}
              severity={"danger"}
              icon="pi pi-trash"
            />
          </div>
        </div>
      ))}
      <div className="grid grid-cols-5 gap-2 mt-4 items-center">
        <div className="col-span-2">
          <Dropdown
            className="p-dropdown-sm w-full"
            options={
              doctorServices
                ? doctorServices
                    .filter((service) => !services.includes(service.name))
                    .map((service) => ({
                      value: service.value,
                      name: service.name,
                    }))
                : []
            }
            optionLabel="name"
            optionValue="value"
            placeholder="Select a Service"
          />
        </div>

        <div className="col-span-2">
          <InputNumber className="p-inputnumber-sm w-full" label="Price" />
        </div>
        <div className="col-span-1">
          <Button
            size="small"
            className="p-button-text"
            label="Add Service"
            icon="pi pi-plus"
          />
        </div>
      </div>
      <div className="flex justify-end mt-4">
        <Button label="Save" />
      </div>
    </div>
  );
}

export default EditDoctorServices;
