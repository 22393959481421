import React, { useContext, useEffect } from "react";
import axios from "axios";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { UserContext } from "../../contexts/UserContext";
import {
  groomingServicesOptions,
  petServicesOptions,
} from "../../types/institution.types";
import { FloatLabel } from "primereact/floatlabel";
import { handleResponseError } from "../../utils/handleResponseError";

function AddDoctorPetService({
  doctorId,
  setModalVisible,
  setServices,
  representorId,
  institutionType,
  institutionId,

  ownResource = false,
}) {
  const { user } = useContext(UserContext);

  const addInstitutionService = useFormik({
    initialValues: {
      title: "",
      description: "",
      type: institutionType === "CENTER" ? "GROOMING" : "",
      representor: representorId,
      physician: doctorId,
      institution: institutionId,
      offerPrice: 0,
      offerCurrency: "",
    },
    validate: (data) => {
      let errors = {};

      let requiredFields = [
        {
          name: "title",
          label: "Title",
        },
        {
          name: "offerPrice",
          label: "Offer Price",
        },
      ];

      requiredFields.forEach((field) => {
        if (!data[field.name]) {
          errors[field.name] = `${field.label} is required`;
        }
      });

      return errors;
    },
    onSubmit: (data) => {
      if (data) {
        data.offer = {
          price: data.offerPrice,
          priceCurrency: data.offerCurrency,
        };

        delete data.offerPrice;
        delete data.offerCurrency;

        let endpoint;

        if (ownResource) endpoint = "/service/private";
        if (!ownResource) endpoint = "/service";

        axios
          .post(endpoint, data)
          .then((res) => {
            setModalVisible(false);
            res.data && setServices((prev) => [...prev, res.data]);
          })
          .catch((e) => {
            handleResponseError(e);
          });
      }
    },
  });

  const isFormFieldInvalid = (name) =>
    !!(
      addInstitutionService.touched[name] && addInstitutionService.errors[name]
    );

  const getFormErrorMessage = (name) => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{addInstitutionService.errors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  useEffect(() => {
    addInstitutionService.resetForm();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let currency;

    if (user.country === "EG") currency = "EGP";
    if (user.country === "SA") currency = "SAR";

    addInstitutionService.setFieldValue("offerCurrency", currency);
    // eslint-disable-next-line
  }, [user]);

  return (
    <div>
      <span className="font-bold my-10">Service Information</span>
      <hr className="pb-10" />
      {institutionType && (
        <div className="grid grid-cols-2 gap-5">
          <form
            onSubmit={addInstitutionService.handleSubmit}
            className="col-span-2"
          >
            <div className="flex flex-col gap-4 w-full">
              <div className="flex flex-col gap-2 w-full">
                <div className="grid grid-cols-3 gap-3">
                  <div className="col-span-3">
                    <span className="p-float-label w-full">
                      <Dropdown
                        options={
                          institutionType === "CENTER"
                            ? groomingServicesOptions
                            : petServicesOptions
                        }
                        disabled={institutionType === "CENTER" ? true : false}
                        optionLabel="label"
                        optionValue="value"
                        id="type"
                        name="type"
                        value={addInstitutionService.values.type}
                        onChange={(e) => {
                          addInstitutionService.setFieldValue("type", e.value);
                        }}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("type"),
                          "w-full": true,
                        })}
                      />
                      <label htmlFor="type">Type</label>
                    </span>
                    {getFormErrorMessage("type")}
                  </div>
                  <div className="col-span-3">
                    <span className="p-float-label w-full">
                      <InputText
                        id="title"
                        name="title"
                        value={addInstitutionService.values.title}
                        onChange={(e) => {
                          addInstitutionService.setFieldValue(
                            "title",
                            e.target.value
                          );
                        }}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("title"),
                          "w-full": true,
                        })}
                      />
                      <label htmlFor="title">Title</label>
                    </span>
                    {getFormErrorMessage("title")}
                  </div>
                  <div className="col-span-3">
                    <span className="p-float-label w-full">
                      <InputText
                        id="description"
                        name="description"
                        value={addInstitutionService.values.description}
                        onChange={(e) => {
                          addInstitutionService.setFieldValue(
                            "description",
                            e.target.value
                          );
                        }}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("description"),
                          "w-full": true,
                        })}
                      />
                      <label htmlFor="description">Description</label>
                    </span>
                    {getFormErrorMessage("description")}
                  </div>
                  <div className="col-span-2">
                    <FloatLabel>
                      <InputNumber
                        id="offerPrice"
                        name="offerPrice"
                        value={addInstitutionService.values.offerPrice}
                        onChange={(e) => {
                          addInstitutionService.setFieldValue(
                            "offerPrice",
                            e.value
                          );
                        }}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("offerPrice"),
                          "w-full": true,
                        })}
                      />
                      <label for="offerPrice">Price</label>
                    </FloatLabel>
                    {getFormErrorMessage("offerPrice")}
                  </div>
                  <div className="col-span-1">
                    <FloatLabel>
                      <InputText
                        id="offerCurrency"
                        disabled
                        name="offerCurrency"
                        value={addInstitutionService.values.offerCurrency}
                        onChange={(e) => {
                          addInstitutionService.setFieldValue(
                            "offerCurrency",
                            e.target.value
                          );
                        }}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("offerCurrency"),
                          "w-full": true,
                        })}
                      />
                      <label for="offerCurrency">Currency</label>
                    </FloatLabel>
                  </div>
                </div>
              </div>

              <div className="flex flex-col gap-2">
                <Button
                  loading={addInstitutionService.isSubmitting}
                  disabled={
                    addInstitutionService.isSubmitting ||
                    !addInstitutionService.dirty ||
                    !addInstitutionService.isValid
                  }
                  type="submit"
                  label="Add Service"
                />
              </div>
            </div>
          </form>
          <div className="col-span-1"></div>
        </div>
      )}
    </div>
  );
}

export default AddDoctorPetService;
