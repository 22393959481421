import React, { useCallback, useEffect, useState } from "react";
import { Avatar } from "primereact/avatar";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { Tag } from "primereact/tag";
import { Button } from "primereact/button";
import { userRoles } from "../../types/users.types";
import { Skeleton } from "primereact/skeleton";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { handleResponseError } from "../../utils/handleResponseError";

function ViewUser() {
  const params = useParams();
  const navigate = useNavigate();

  const [userLoading, setUserLoading] = useState(true);
  const [userStatusLoading, setUserStatusLoading] = useState(false);

  const [user, setUser] = useState({});
  const [userInstitutions, setInstitutions] = useState([]);

  const getUser = () => {
    axios
      .get(`/user/${params._id}`)
      .then((response) => {
        setUser(response.data);
        setUserLoading(false);
      })
      .catch(() => {
        setUserLoading(false);
      });
  };

  const getUserInstitutions = () => {
    axios
      .get(`/institution/list`, {
        params: {
          representor: [params._id],
        },
      })
      .then((response) => {
        setInstitutions(response.data);
      });
  };

  const deactivateUser = useCallback(() => {
    setUserStatusLoading(true);

    axios
      .patch(`/user/disable/${params._id}`)
      .then(() => {
        toast("User deactivated successfully", {
          type: "success",
        });
        setUser({ ...user, isActive: false });
      })
      .catch((e) => {
        handleResponseError(e);
      })
      .finally(() => setUserStatusLoading(false));
  }, [params._id, user]);

  const activateUser = useCallback(() => {
    setUserStatusLoading(true);

    axios
      .patch(`/user/enable/${params._id}`)
      .then(() => {
        toast("User activated successfully", {
          type: "success",
        });
        setUser({ ...user, isActive: true });
      })
      .catch((e) => {
        handleResponseError(e);
      })
      .finally(() => setUserStatusLoading(false));
  }, [params._id, user]);

  useEffect(() => {
    getUser();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (user.role === "REPRESENTOR") {
      getUserInstitutions();
    }
    // eslint-disable-next-line
  }, [user]);

  const actionsBodyTemplate = (rowData) => {
    return (
      <div className="flex align-items-center gap-2">
        <Button
          onClick={() => {
            navigate(`/institutions/${rowData._id}`);
          }}
          severity="primary"
          label="View"
          icon="pi pi-eye"
          size="small"
        />
        <Button
          severity="danger"
          label="Delete"
          icon="pi pi-trash"
          size="small"
        />
      </div>
    );
  };

  return (
    <>
      {userLoading ? (
        <Skeleton width="100%" height="200px"></Skeleton>
      ) : (
        <div className="bg-gray-200 w-full h-[200px] hidden md:flex"></div>
      )}
      <div className="flex flex-col md:flex-row w-full justify-between md:-mt-[70px] bg-white rounded-tl-full">
        <div className="flex gap-2 items-center">
          {userLoading ? (
            <Skeleton
              shape="circle"
              size="150px"
              className="rounded-full border-2 border-gray-200"
            ></Skeleton>
          ) : (
            <Avatar
              label={user.name && user.name[0]}
              image={user.avatar && user.avatar}
              className="border-2 border-gray-200 overflow-hidden"
              style={{
                borderRadius: "100%",
                width: 150,
                height: 150,
                fontSize: "3rem",
              }}
            />
          )}

          <div>
            <div className="flex gap-2 items-center">
              {userLoading ? (
                <Skeleton width="150px"></Skeleton>
              ) : (
                <div className="flex flex-col md:flex-row gap-1">
                  <h1 className="text-3xl font-bold">{user.name}</h1>
                  <Tag className="w-fit text-[0.7rem] p-1" color="green">
                    {userRoles[user.role]}
                  </Tag>
                </div>
              )}
            </div>
            {userLoading ? (
              <Skeleton width="150px" className="mt-2"></Skeleton>
            ) : (
              <p className="text-lg">{user.email}</p>
            )}
          </div>
        </div>
        <div className="flex justify-end gap-2 items-center">
          {!userLoading && (
            <>
              {user.isActive ? (
                <Button
                  className="p-button-primary"
                  severity="danger"
                  onClick={deactivateUser}
                  loading={userStatusLoading}
                  disabled={userStatusLoading}
                  label="Deactivate"
                  size="small"
                />
              ) : (
                <Button
                  className="p-button-primary"
                  severity="help"
                  onClick={activateUser}
                  loading={userStatusLoading}
                  disabled={userStatusLoading}
                  label="Activate"
                  size="small"
                />
              )}
            </>
          )}
        </div>
      </div>
      {user && user.role === "REPRESENTOR" && (
        <div className="bg-white p-4 rounded-md shadow-sm mt-10">
          <div className="flex justify-between my-2">
            <h2 className="text-2xl font-bold">Institutions</h2>
            <Button
              onClick={() => {
                navigate(`/institutions/add/${user._id}`);
              }}
              label="Add Institution"
              icon="pi pi-plus"
              className="p-button-primary p-button-sm p-button-text"
            />
          </div>
          <div className="flex gap-2 flex-wrap">
            {userLoading ? (
              <>
                <Skeleton width="100px" height="30px"></Skeleton>
                <Skeleton width="100px" height="30px"></Skeleton>
                <Skeleton width="100px" height="30px"></Skeleton>
                <Skeleton width="100px" height="30px"></Skeleton>
              </>
            ) : (
              userInstitutions && (
                <DataTable
                  value={userInstitutions}
                  className="p-datatable-sm w-full"
                  autoLayout
                  resizableColumns
                  columnResizeMode="expand"
                >
                  <Column
                    field="avatar"
                    header=""
                    body={(rowData) => {
                      return (
                        <Avatar
                          label={rowData.name && rowData.name[0]}
                          image={rowData.avatar && rowData.avatar}
                          className="border-2 border-gray-200"
                          size="xlarge"
                        />
                      );
                    }}
                  ></Column>
                  <Column field="name" header="Name"></Column>
                  <Column
                    field="type"
                    header="Type"
                    body={(rowData) => {
                      return <Tag value={rowData.type} severity="info" />;
                    }}
                  ></Column>
                  <Column
                    header="Actions"
                    body={actionsBodyTemplate}
                    style={{ minWidth: "12rem" }}
                  />
                </DataTable>
              )
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default ViewUser;
