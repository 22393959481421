import axios from "axios";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import {
  renderBookingPaymentStatus,
  renderBookingPaymentStatusBackground,
  renderBookingStatus,
  renderBookingStatusBackground,
} from "../../../types/booking.types";
import { Badge } from "primereact/badge";
import { Divider } from "primereact/divider";
import { Accordion, AccordionTab } from "primereact/accordion";
import { GoogleMap, MarkerF, useJsApiLoader } from "@react-google-maps/api";
import mapMarkerImage from "../../../assets/images/map-marker.svg";
import mapStyle from "../../components/map/map-style";

const renderAnimalType = (type) => {
  const types = {
    DOG: "Dog",
    CAT: "Cat",
    BIRD: "Bird",
  };

  return types[type];
};

const renderAnimalHair = (hairLength) => {
  const options = {
    SHORT: "Short",
    Long: "Long",
  };

  return options[hairLength];
};

function ViewBookingDetails({ bookingId, ownResource = true }) {
  const [booking, setBooking] = useState(null);
  const [map, setMap] = useState(null);
  const [decodedAddress, setDecodedAddress] = useState(null);

  const loaderOptions = useMemo(
    () => ({
      googleMapsApiKey: "AIzaSyBYpxK044g3MKhT_9VcoMknC7l69IQE_xM",
      mapIds: ["bfab162a59c71a8e"],
      id: "bfab162a59c71a8e",
    }),
    []
  );

  const { isLoaded } = useJsApiLoader(loaderOptions);

  const onLoad = React.useCallback(function callback(map) {
    if (
      booking &&
      booking?.location?.coordinates?.latitude &&
      booking?.location?.coordinates?.longitude
    ) {
      new window.google.maps.Marker({
        position: {
          lat: booking.location.coordinates.latitude,
          lng: booking.location.coordinates.longitude,
        },
        icon: {
          url: mapMarkerImage,
          scaledSize: { width: 60, height: 60 },
        },
        map,
        draggable: false,
      });
    }

    setMap(map);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const checkDecodedAddress = (location) => {
    const geocoder = new window.google.maps.Geocoder();

    geocoder.geocode(
      {
        location: {
          lat: location.latitude,
          lng: location.longitude,
        },
      },
      (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            console.log(results[0]);
            setDecodedAddress(results[0].formatted_address);
          } else {
            setDecodedAddress(null);
          }
        } else {
          setDecodedAddress(null);
        }
      }
    );
  };

  useEffect(() => {
    let endpoint;

    if (ownResource) endpoint = `/appointment/private/${bookingId}`;
    if (!ownResource) endpoint = `/schedule/${bookingId}`;

    axios
      .get(endpoint, {
        params: {
          populateAnimal: true,
        },
      })
      .then((res) => {
        setBooking(res.data);

        if (res.data.location) {
          checkDecodedAddress(res.data.location.coordinates);
        }
      });
  }, [bookingId, ownResource]);

  return (
    <>
      <h1 className="text-2xl font-bold">View Booking</h1>
      {booking && (
        <div className="flex flex-col gap-4 mt-5">
          <div className="flex flex-col">
            <small className="text-gray-500">Booking Time</small>
            <span className="text-xl font-semibold">
              {moment(booking.time, "X").format("DD-MM-YYYY HH:mm")}
            </span>
          </div>
          <div className="flex flex-col">
            <small className="text-gray-500">Total Price</small>
            <span className="text-xl font-semibold">
              {new Intl.NumberFormat("en-EG", {
                style: "currency",
                currency: "EGP",
              }).format(booking.total)}
            </span>
          </div>
          <div className="flex flex-col">
            <small className="text-gray-500">Name</small>
            <span className="text-xl font-semibold">
              {booking.user.name || "N/A"}
            </span>
          </div>
          <div className="flex flex-col">
            <small className="text-gray-500">Pet Name</small>
            <span className="text-xl font-semibold">
              {booking.animal.name || "N/A"}
            </span>
          </div>

          <div className="flex flex-col">
            <small className="text-gray-500">Status</small>
            <span className="text-xl font-semibold">
              <Badge
                severity={renderBookingStatusBackground(booking.status)}
                value={renderBookingStatus(booking.status)}
              />
            </span>
          </div>
          <div className="flex flex-col">
            <small className="text-gray-500">Payment Status</small>
            <span className="text-xl font-semibold">
              <Badge
                severity={renderBookingPaymentStatusBackground(
                  booking.paymentStatus
                )}
                value={renderBookingPaymentStatus(booking.paymentStatus)}
              />
            </span>
          </div>
          <div className="flex flex-col">
            <small className="text-gray-500">Insititution</small>
            <span className="text-xl font-semibold">
              {booking.institution.name}
            </span>
          </div>
          {booking.services?.[0]?.physician && (
            <div className="flex flex-col">
              <small className="text-gray-500">Insititution</small>
              <span className="text-xl font-semibold">
                {booking.services?.[0]?.physician?.name}
              </span>
            </div>
          )}

          <Divider />

          <div className="bg-gray-50 p-4 rounded-md flex flex-col gap-7">
            <span className="text-md font-bold">Services</span>
            {booking.services?.map((service) => (
              <div className="flex w-full justify-between" key={service._id}>
                <small className="text-sm text-gray-500 font-bold">
                  {service.title}
                </small>
                <small className="text-sm text-gray-500 font-bold">
                  {new Intl.NumberFormat("en-EG", {
                    style: "currency",
                    currency: service.offers[0].priceCurrency,
                  }).format(service.offers[0].price)}
                </small>
              </div>
            ))}
          </div>
          <Divider />

          {decodedAddress && (
            <div className="flex flex-col">
              <small className="text-gray-500">Location</small>
              <span className="text-xl font-semibold">{decodedAddress}</span>
            </div>
          )}

          {isLoaded && booking && booking?.location?.coordinates && (
            <div className="flex w-full justify-between">
              <GoogleMap
                ref={map}
                mapContainerStyle={{
                  width: "500px",
                  height: "300px",
                }}
                options={{
                  streetViewControl: false,
                  mapTypeControl: false,
                  zoomControl: 8,
                  style: mapStyle,
                }}
                center={{
                  lat: booking.location.coordinates.latitude,
                  lng: booking.location.coordinates.longitude,
                }}
                onLoad={onLoad}
                onUnmount={onUnmount}
                zoom={15}
              >
                <MarkerF
                  options={{
                    draggable: false,
                  }}
                  icon={{
                    url: mapMarkerImage,
                    scaledSize: new window.google.maps.Size(60, 60),
                  }}
                  position={{
                    lat: booking.location.coordinates.latitude,
                    lng: booking.location.coordinates.longitude,
                  }}
                />
              </GoogleMap>
            </div>
          )}

          {booking.animal.type && (
            <div className="flex flex-col">
              <Accordion>
                <AccordionTab header="Pet Details">
                  <div className="flex flex-col gap-2">
                    <div className="flex w-full justify-between">
                      <small className="text-sm text-gray-500 font-bold">
                        Name
                      </small>
                      <small className="text-sm text-gray-500 font-bold">
                        {booking.animal?.name}
                      </small>
                    </div>
                    <div className="flex w-full justify-between">
                      <small className="text-sm text-gray-500 font-bold">
                        Type
                      </small>
                      <small className="text-sm text-gray-500 font-bold">
                        {renderAnimalType(booking.animal?.type)}
                      </small>
                    </div>
                    <div className="flex w-full justify-between">
                      <small className="text-sm text-gray-500 font-bold">
                        Breed
                      </small>
                      <small className="text-sm text-gray-500 font-bold">
                        {booking.animal?.breed}
                      </small>
                    </div>
                    {booking.animal.hair && (
                      <div className="flex w-full justify-between">
                        <small className="text-sm text-gray-500 font-bold">
                          Hair
                        </small>
                        <small className="text-sm text-gray-500 font-bold">
                          {renderAnimalHair(booking.animal?.hair)}
                        </small>
                      </div>
                    )}
                  </div>
                </AccordionTab>
              </Accordion>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default ViewBookingDetails;
