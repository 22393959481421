export const doctorServices = [
  {
    value: "service_1",
    name: "Service 1",
    price: 100,
  },
  {
    value: "service_2",
    name: "Service 2",
    price: 200,
  },
  {
    value: "service_3",
    name: "Service 3",
    price: 300,
  },
];
