import { Divider } from "primereact/divider";
import { motion } from "framer-motion";
import React, { useContext } from "react";
import { UserContext } from "../../contexts/UserContext";
import { InstitutionsContext } from "../../contexts/InstitutionsContext";
import { Link, NavLink } from "react-router-dom";

function DashboardHome() {
  const { user } = useContext(UserContext);
  const { institutions } = useContext(InstitutionsContext);

  return (
    <div>
      <h1 className="text-4xl font-bold">Hello, {user?.name}!</h1>
      <Divider className="my-4" />
      {user?.role === "ADMIN" ? (
        <div className="cards grid grid-cols-3 gap-5">
          {/* <motion.div
            whileTap={{
              scale: 1.1,
              transition: { duration: 0.5 },
            }}
            className="card col-span-3 md:col-span-1 flex justify-between items-center p-4 rounded-lg shadow-md bg-gradient-to-t from-orange-500 to-red-500 text-white"
          >
            <div>
              <h2 className="text-md font-bold">Total Users</h2>
              <p className="text-2xl md:text-4xl font-bold">100</p>
            </div>
            <p className="text-2xl md:text-4xl font-bold">
              <FaUser />
            </p>
          </motion.div>
          <motion.div
            whileTap={{
              scale: 1.1,
              transition: { duration: 0.5 },
            }}
            className="card col-span-3 md:col-span-1 flex justify-between items-center p-4 rounded-lg shadow-md bg-gradient-to-t from-blue-500 to-cyan-500 text-white"
          >
            <div>
              <h2 className="text-md font-bold">Total Institutions</h2>
              <p className="text-2xl md:text-4xl font-bold">10</p>
            </div>
            <p className="text-2xl md:text-4xl font-bold">
              <FaHospitalSymbol />
            </p>
          </motion.div>
          <motion.div
            whileTap={{
              scale: 1.1,
              transition: { duration: 0.5 },
            }}
            className="card col-span-3 md:col-span-1 flex justify-between items-center p-4 rounded-lg shadow-md bg-gradient-to-t from-violet-500 to-pink-500 text-white"
          >
            <div>
              <h2 className="text-md font-bold">Total Bookings</h2>
              <p className="text-2xl md:text-4xl font-bold">504404</p>
            </div>
            <p className="text-2xl md:text-4xl font-bold">
              <FaTicketAlt />
            </p>
          </motion.div> */}
        </div>
      ) : null}

      {user?.role === "REPRESENTOR" ? (
        <div className="cards grid grid-cols-3 gap-5 card">
          <Link className="col-span-3 md:col-span-1" to="rep-bookings">
            <motion.div
              whileTap={{
                scale: 1.1,
                transition: { duration: 0.5 },
              }}
              className="flex justify-between items-center p-4 rounded-lg shadow-md bg-gradient-to-t from-orange-500 to-red-500 text-white"
            >
              <div>
                <h2 className="text-md font-bold">Manage Bookings</h2>
              </div>
            </motion.div>
          </Link>
          <div className="col-span-3 md:col-span-2 p-4 shadow-sm">
            <h1 className="text-xl font-semibold text-gray-500">Quick Links</h1>
            <hr />
            <div className="mt-2">
              {institutions &&
                institutions.length &&
                institutions.map((institution) => (
                  <NavLink to={`institutions/private/${institution._id}`}>
                    <div className="flex items-center gap-2">
                      <span>></span>
                      <p className="underline">{institution.name}</p>
                    </div>
                  </NavLink>
                ))}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default DashboardHome;
