import React, { useEffect } from "react";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch";
import axios from "axios";
import { toast } from "react-toastify";

function EditInstitutionSchedule({
  setVisible,
  institution,
  setInstitution,
  ownResource = false,
}) {
  const institutionScheduleForm = useFormik({
    initialValues: {
      Sun: {
        from: "",
        to: "",
        enabled: false,
      },
      Mon: {
        from: "",
        to: "",
        enabled: false,
      },
      Tue: {
        from: "",
        to: "",
        enabled: false,
      },
      Wed: {
        from: "",
        to: "",
        enabled: false,
      },
      Thu: {
        from: "",
        to: "",
        enabled: false,
      },
      Fri: {
        from: "",
        to: "",
        enabled: false,
      },
      Sat: {
        from: "",
        to: "",
        enabled: false,
      },
    },
    onSubmit: (values) => {
      let _values = [];

      for (let i = 0; i < Object.values(values).length; i++) {
        if (Object.values(values)[i].enabled) {
          _values.push({
            day: Object.keys(values)[i],
            from: Object.values(values)[i].from,
            to: Object.values(values)[i].to,
          });
        }
      }

      let reqBody = {
        availableHours: _values,
      };

      handleEditDoctorSchedule(reqBody);
    },
  });

  const handleEditDoctorSchedule = (requestBody) => {
    let endpoint = "";

    if (ownResource) {
      endpoint = `/institution/private/available-hours/${institution._id}`;
    } else {
      endpoint = `/institution/available-hours/${institution._id}`;
    }

    axios
      .patch(endpoint, requestBody)
      .then((res) => {
        setInstitution(res.data);
      })
      .catch((e) => {
        toast("Something Went wrong", {
          type: "error",
        });
      })
      .finally(() => {
        setVisible(false);
      });
  };

  useEffect(() => {
    const _values = {};

    institution.availableHours.forEach((day) => {
      _values[day.day] = { from: day.from, to: day.to, enabled: true };
    });

    institutionScheduleForm.setValues({
      ...institutionScheduleForm.values,
      ..._values,
    });
    // eslint-disable-next-line
  }, []);

  return (
    <form onSubmit={institutionScheduleForm.handleSubmit}>
      <div className="flex flex-col gap-3">
        <div className="grid grid-cols-12 items-center">
          <div className="col-span-1">
            <InputSwitch
              checked={institutionScheduleForm.values.Sun.enabled}
              onChange={(e) => {
                institutionScheduleForm.setFieldValue(
                  "Sun.enabled",
                  e.target.value
                );
              }}
            />
          </div>
          <div className="col-span-11">
            <div className="grid grid-cols-12 gap-4  items-center">
              <div className="col-span-4 flex flex-col">
                <small>Day</small>
                <label htmlFor="day">Sun</label>
              </div>
              <div className="col-span-4">
                <small htmlFor="from">From</small>
                <input
                  onChange={(e) =>
                    institutionScheduleForm.setFieldValue(
                      "Sun.from",
                      e.target.value
                    )
                  }
                  value={institutionScheduleForm.values.Sun.from}
                  type="time"
                  id="from"
                  name="from"
                  className="w-full"
                  disabled={!institutionScheduleForm.values.Sun.enabled}
                />
              </div>
              <div className="col-span-4">
                <label htmlFor="to">To</label>
                <input
                  onChange={(e) =>
                    institutionScheduleForm.setFieldValue(
                      "Sun.to",
                      e.target.value
                    )
                  }
                  value={institutionScheduleForm.values.Sun.to}
                  type="time"
                  id="to"
                  name="to"
                  className="w-full"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-12 items-center">
          <div className="col-span-1">
            <InputSwitch
              checked={institutionScheduleForm.values.Mon.enabled}
              onChange={(e) => {
                institutionScheduleForm.setFieldValue(
                  "Mon.enabled",
                  e.target.value
                );
              }}
            />
          </div>
          <div className="col-span-11">
            <div className="grid grid-cols-12 gap-4  items-center">
              <div className="col-span-4 flex flex-col">
                <small>Day</small>
                <label htmlFor="day">Mon</label>
              </div>
              <div className="col-span-4">
                <small htmlFor="from">From</small>
                <input
                  onChange={(e) =>
                    institutionScheduleForm.setFieldValue(
                      "Mon.from",
                      e.target.value
                    )
                  }
                  value={institutionScheduleForm.values.Mon.from}
                  type="time"
                  id="from"
                  name="from"
                  className="w-full"
                  disabled={!institutionScheduleForm.values.Mon.enabled}
                />
              </div>
              <div className="col-span-4">
                <label htmlFor="to">To</label>
                <input
                  onChange={(e) =>
                    institutionScheduleForm.setFieldValue(
                      "Mon.to",
                      e.target.value
                    )
                  }
                  value={institutionScheduleForm.values.Mon.to}
                  type="time"
                  id="to"
                  name="to"
                  className="w-full"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-12 items-center">
          <div className="col-span-1">
            <InputSwitch
              checked={institutionScheduleForm.values.Tue.enabled}
              onChange={(e) => {
                institutionScheduleForm.setFieldValue(
                  "Tue.enabled",
                  e.target.value
                );
              }}
            />
          </div>
          <div className="col-span-11">
            <div className="grid grid-cols-12 gap-4  items-center">
              <div className="col-span-4 flex flex-col">
                <small>Day</small>
                <label htmlFor="day">Tue</label>
              </div>
              <div className="col-span-4">
                <small htmlFor="from">From</small>
                <input
                  onChange={(e) =>
                    institutionScheduleForm.setFieldValue(
                      "Tue.from",
                      e.target.value
                    )
                  }
                  value={institutionScheduleForm.values.Tue.from}
                  type="time"
                  id="from"
                  name="from"
                  className="w-full"
                />
              </div>
              <div className="col-span-4">
                <label htmlFor="to">To</label>
                <input
                  onChange={(e) =>
                    institutionScheduleForm.setFieldValue(
                      "Tue.to",
                      e.target.value
                    )
                  }
                  value={institutionScheduleForm.values.Tue.to}
                  type="time"
                  id="to"
                  name="to"
                  className="w-full"
                  disabled={!institutionScheduleForm.values.Tue.enabled}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-12 items-center">
          <div className="col-span-1">
            <InputSwitch
              checked={institutionScheduleForm.values.Wed.enabled}
              onChange={(e) => {
                institutionScheduleForm.setFieldValue(
                  "Wed.enabled",
                  e.target.value
                );
              }}
            />
          </div>
          <div className="col-span-11">
            <div className="grid grid-cols-12 gap-4  items-center">
              <div className="col-span-4 flex flex-col">
                <small>Day</small>
                <label htmlFor="day">Wed</label>
              </div>
              <div className="col-span-4">
                <small htmlFor="from">From</small>
                <input
                  onChange={(e) =>
                    institutionScheduleForm.setFieldValue(
                      "Wed.from",
                      e.target.value
                    )
                  }
                  value={institutionScheduleForm.values.Wed.from}
                  type="time"
                  id="from"
                  name="from"
                  className="w-full"
                  disabled={!institutionScheduleForm.values.Wed.enabled}
                />
              </div>
              <div className="col-span-4">
                <label htmlFor="to">To</label>
                <input
                  onChange={(e) =>
                    institutionScheduleForm.setFieldValue(
                      "Wed.to",
                      e.target.value
                    )
                  }
                  value={institutionScheduleForm.values.Wed.to}
                  type="time"
                  id="to"
                  name="to"
                  className="w-full"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-12 items-center">
          <div className="col-span-1">
            <InputSwitch
              checked={institutionScheduleForm.values.Thu.enabled}
              onChange={(e) => {
                institutionScheduleForm.setFieldValue(
                  "Thu.enabled",
                  e.target.value
                );
              }}
            />
          </div>
          <div className="col-span-11">
            <div className="grid grid-cols-12 gap-4  items-center">
              <div className="col-span-4 flex flex-col">
                <small>Day</small>
                <label htmlFor="day">Thu</label>
              </div>
              <div className="col-span-4">
                <small htmlFor="from">From</small>
                <input
                  onChange={(e) =>
                    institutionScheduleForm.setFieldValue(
                      "Thu.from",
                      e.target.value
                    )
                  }
                  value={institutionScheduleForm.values.Thu.from}
                  type="time"
                  id="from"
                  name="from"
                  className="w-full"
                  disabled={!institutionScheduleForm.values.Thu.enabled}
                />
              </div>
              <div className="col-span-4">
                <label htmlFor="to">To</label>
                <input
                  onChange={(e) =>
                    institutionScheduleForm.setFieldValue(
                      "Thu.to",
                      e.target.value
                    )
                  }
                  value={institutionScheduleForm.values.Thu.to}
                  type="time"
                  id="to"
                  name="to"
                  className="w-full"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-12 items-center">
          <div className="col-span-1">
            <InputSwitch
              checked={institutionScheduleForm.values.Fri.enabled}
              onChange={(e) => {
                institutionScheduleForm.setFieldValue(
                  "Fri.enabled",
                  e.target.value
                );
              }}
            />
          </div>
          <div className="col-span-11">
            <div className="grid grid-cols-12 gap-4  items-center">
              <div className="col-span-4 flex flex-col">
                <small>Day</small>
                <label htmlFor="day">Fri</label>
              </div>
              <div className="col-span-4">
                <small htmlFor="from">From</small>
                <input
                  onChange={(e) =>
                    institutionScheduleForm.setFieldValue(
                      "Fri.from",
                      e.target.value
                    )
                  }
                  value={institutionScheduleForm.values.Fri.from}
                  type="time"
                  id="from"
                  name="from"
                  className="w-full"
                  disabled={!institutionScheduleForm.values.Fri.enabled}
                />
              </div>
              <div className="col-span-4">
                <label htmlFor="to">To</label>
                <input
                  onChange={(e) =>
                    institutionScheduleForm.setFieldValue(
                      "Fri.to",
                      e.target.value
                    )
                  }
                  value={institutionScheduleForm.values.Fri.to}
                  type="time"
                  id="to"
                  name="to"
                  className="w-full"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-12 items-center">
          <div className="col-span-1">
            <InputSwitch
              checked={institutionScheduleForm.values.Sat.enabled}
              onChange={(e) => {
                institutionScheduleForm.setFieldValue(
                  "Sat.enabled",
                  e.target.value
                );
              }}
            />
          </div>
          <div className="col-span-11">
            <div className="grid grid-cols-12 gap-4  items-center">
              <div className="col-span-4 flex flex-col">
                <small>Day</small>
                <label htmlFor="day">Sat</label>
              </div>
              <div className="col-span-4">
                <small htmlFor="from">From</small>
                <input
                  onChange={(e) =>
                    institutionScheduleForm.setFieldValue(
                      "Sat.from",
                      e.target.value
                    )
                  }
                  value={institutionScheduleForm.values.Sat.from}
                  type="time"
                  id="from"
                  name="from"
                  className="w-full"
                  disabled={!institutionScheduleForm.values.Sat.enabled}
                />
              </div>
              <div className="col-span-4">
                <label htmlFor="to">To</label>
                <input
                  onChange={(e) =>
                    institutionScheduleForm.setFieldValue(
                      "Sat.to",
                      e.target.value
                    )
                  }
                  value={institutionScheduleForm.values.Sat.to}
                  type="time"
                  id="to"
                  name="to"
                  className="w-full"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end">
          <Button label="Save" className="p-button-raised" />
        </div>
      </div>
    </form>
  );
}

export default EditInstitutionSchedule;
